var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{key:'hp' + _vm.idx,ref:"card",staticClass:"hotspot-list__card",attrs:{"idx":_vm.idx,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')},"mouseover":function($event){return _vm.$emit('mouseover')},"mouseout":function($event){return _vm.$emit('mouseout')}}},[_c('div',{ref:"container",staticClass:"hotspot-list__card__container"},[_c('div',{staticClass:"hotspot-list__card__thumbnail"},[_c('div',{ref:"imageWrapper",staticClass:"hotspot-list__card__thumbnail__wrapper"},[_c('div',{staticClass:"hotspot-list__card__thumbnail__container"},[(_vm.content.banner && _vm.content.banner[2])?_c('img',{staticClass:"\n              hotspot-list__card__image\n              hotspot-list__card__image\n              hotspot-list__card__image--banner\n            ",attrs:{"width":_vm.content.banner[2].meta.width,"height":_vm.content.banner[2].meta.height,"src":_vm.content.banner[2].src}}):_vm._e(),(
              _vm.content.image &&
              !_vm.content.banner &&
              _vm.content.image[0] &&
              !_vm.isMobile
            )?_c('img',{staticClass:"\n              hotspot-list__card__image hotspot-list__card__image--desktop\n            ",attrs:{"width":_vm.content.image[0].meta.width,"height":_vm.content.image[0].meta.height,"src":_vm.content.image[0].src}}):_vm._e(),(
              _vm.content.image && !_vm.content.banner && _vm.content.image[1] && _vm.isMobile
            )?_c('img',{staticClass:"\n              hotspot-list__card__image hotspot-list__card__image--mobile\n            ",attrs:{"width":_vm.content.image[1].meta.width,"height":_vm.content.image[1].meta.height,"src":_vm.content.image[1].src}}):_vm._e(),(_vm.content.teamLogo)?_c('div',{staticClass:"hotspot-list__card__team-logo__container"},[_c('img',{staticClass:"\n                hotspot-list__card__team-logo hotspot-list__card__team-logo\n              ",attrs:{"width":_vm.content.teamLogo[2].meta.width,"height":_vm.content.teamLogo[2].meta.height,"src":_vm.content.teamLogo[2].src}})]):_vm._e()])])]),_c('div',{staticClass:"hotspot-list__card__content"},[_c('div',{staticClass:"hotspot-list__card__title-wrapper"},[_c('span',{ref:"title",staticClass:"hotspot-list__card__title-wrapper__title title"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }