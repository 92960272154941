
import GlobeController from "./entities/nba/GlobeController";
// import IBLManager from "./gl/IBLManager";
import IModule from "./IModule";
import Scene from "./Scene";


export default class MainModule implements IModule {
  
  scene: Scene
  globeCtrl:GlobeController

  _onPreRender:Function

  constructor(onPreRender:Function = null) {
    this._onPreRender = onPreRender
  }


  init( scene: Scene): void {
    
    this.scene = scene
    this.scene.envRotation = 0
    
    this.globeCtrl = new GlobeController(this.scene)

  }

  
  preRender(): void {
    this.globeCtrl.preRender()
    if(this._onPreRender) this._onPreRender()
  }
  
  rttRender(): void {
    this.globeCtrl.rttRender()
  }
  
  render(): void {
    this.globeCtrl.render()
  }


  load(): Promise<void> {
    return (this.globeCtrl.load() as unknown) as Promise<void>
  }

}