import Post from 'nanogl-post'


// import Chromashift  from '@/webgl/glsl/post/chromashift'
import Fetch from 'nanogl-post/effects/fetch'
import VignetteBlur from '../glsl/vignetteblur'
// import Contrast from 'nanogl-post/effects/contrast'
// import Grain from 'nanogl-post/effects/grain'
// import LUT from 'nanogl-post/effects/lut'
// import Saturation from 'nanogl-post/effects/saturation'
// import Sharpen from 'nanogl-post/effects/sharpen'
// import Vignette from 'nanogl-post/effects/vignette'
// import Hejl from 'nanogl-post/effects/hejl'
// import Reinhard from 'nanogl-post/effects/reinhard'
// import Bloom from 'nanogl-post/effects/bloom'
// import Gamma from 'nanogl-post/effects/gamma_tb'
// import Dof from 'nanogl-post/effects/dof'
//import PostDepthDebug     from './post-depth-debug'

// import Quality  from '@/webgl/lib/quality'
 
import glmat, { vec2 } from 'gl-matrix'
import Scene  from '@/webgl/Scene'
import content_state from '@/store/modules/content_state'

/////////////
/////////////////////////////////////////

/////////////////////////////////////////////////////////
/////////////////////////////////
///////////
//////////


var vec3 = glmat.vec3;

export default class PostProcess {

  scene      : Scene
  post       : Post
  // chromashift: Chromashift
  // contrast   : Contrast
  // grain      : Grain
  // sat        : Saturation
  // sharpen    : Sharpen
  // hejl       : Hejl
  // reinhard   : Reinhard
  // bloom      : Bloom
  // vignette   : Vignette
  // dof        : Dof
  fetch         : Fetch
  vignetteblur  : VignetteBlur

  _setup: number


  constructor(scene: Scene) {


    this.scene = scene;

    this.post = new Post(scene.gl, false);
    this.post.enabled = true;

    this.post.add(new Fetch())



    this._setup = 0;

  }

  init() {
    let blurSize = 0.01
    let vignetteStart = 0.1
    let vignetteSize = 0.5
    if(content_state.isMobile) {
      blurSize = 0.015
      vignetteStart = 0.1
      vignetteSize = 0.3
    }
    
    this.vignetteblur = new VignetteBlur(blurSize, vignetteStart, vignetteSize)


/////////////////
///////////////
//////////////
  }

  toggleVignetteBlur(t:boolean) {
    if(t) {
      this.post.add(this.vignetteblur)
    }
    else this.post.remove(this.vignetteblur)
  }




  qualityChange() {
    console.log('quality change')
    // this.post.enabled = Quality.param( 'post' );


    // if( Quality.param('bloom') ){
    //   if( this.bloom.post === null )this.post.add( this.bloom, 1 );
    // }else{
    //   this.post.remove( this.bloom )
    // }

    // if( Quality.param('dof') ){
    //   // if( this.dof.post === null )this.post.add( this.dof, 1 );
    // }else{
    //   // this.post.remove( this.dof )
    // }
  }

  preRender() {
    // var cDist = vec3.distance( this.scene.camera.position, dofCenter );
    // this.dof.focus = cDist + .4;
    // this.dof.near = (cDist - this.dof.focusRange/2 ) - .1
    // this.dof.far  = cDist + 2
  }

  release() {
  }



///////////////
//////////
//////////////////////////////////
/////////////////////////////////
/////////////////////////////////

///////////////////////////////////////////
//////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////////////
////////////////////////////////////////////////
///////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////
///////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////
////////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////
//////////////
//////////////
////////////////
////////
//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////
/////////
//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////
/////////
//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////


///////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
///////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////

////////////////////////////////////////
////////////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////

////////////////////////////////
////////////////////////////////////////
///////////////////////////////////////////

/////////////////////////////////////
//////////////////////////////////////

//////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////

///////////////////////////////////
//////////////////////////////////////////
/////////////////////////////////////////////

////////////////////////////////
///////////////////////////////////////
///////////////////////////

//////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
///
////////////

}
