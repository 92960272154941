import Scene from "@/webgl/Scene";
import { mat4, quat, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import GltfModule from "../GltfModule";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"

import { assetURL, SCALE_HP, US_HOTSPOT_NAME } from "@/common/webgl-vue"
import { polarToCartesian } from "@/webgl/math/PolarCart";
import content_state, { contentTypes } from "@/store/modules/content_state";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { RingAlpha } from "@/webgl/glsl/ringAlpha";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import TexCoord from "@/webgl/lib/nanogl-pbr/src/TexCoord";
import HotspotLights from "./HotspotLights";


const Q = quat.create()
quat.rotateX(Q, Q, -Math.PI * 0.5)

export const GLOBE_MIDDLE = vec3.create()
vec3.set(GLOBE_MIDDLE, 0, 0, 0)

const HOTSPOT_HEIGHT = 1.025

const ANTENNA_SCALE = 1

export default class GlobeModel {


  gltfModule: GltfModule;
  root: Node;

  isLoaded:boolean

  hotspots:Array<GltfNode>
  cocktails:Array<GltfNode>

  hotspotLights: HotspotLights

  ringAlpha:RingAlpha

  imageBasedLight;

  // hotspotRoot:GltfNode

  constructor( private scene: Scene ){
    this.root = new Node()
    this.gltfModule = new GltfModule( scene,
      assetURL(`assets/webgl/globe/NBAxHennessy.gltf`)
    )
    this.gltfModule.zsorting = true

    this.hotspotLights = new HotspotLights(scene)
  }

  async load() : Promise<void> {
    await this.gltfModule.load()

    this.scene.root.add( this.root )
    this.root.add( this.gltfModule.gltf.root )

    const globeNode:GltfNode = this.gltfModule.gltf.getElementByName(GltfTypes.NODE, "globe")

    //Adding article hotspots
    this.hotspots = content_state.articles.map((c, i) => {
      const n:GltfNode = new GltfNode()
      vec3.copy(n.position, polarToCartesian((-c.latlng[1]) - 90, c.latlng[0], HOTSPOT_HEIGHT))
      n.invalidate()
      const name1:string = c.city !== US_HOTSPOT_NAME ? "Cylinder_1" : "Cylinder_tri_1"
      const name2:string = c.city !== US_HOTSPOT_NAME ? "Cylinder" : "Cylinder_tri"
      const cy = this.duplicateGltfNode(name1, n)
      const sw = this.duplicateGltfNode(name2, n)
      this.gltfModule.gltf.nodes.push(sw, cy)
      n.invalidate()
      n.lookAt(GLOBE_MIDDLE)
      n.name = contentTypes.ARTICLE + i
      globeNode.add(n)
      n.extras = { id: c.hotspot_id  }
      if(c.city == US_HOTSPOT_NAME) n.extras.isUS = true
      cy.scale[0] = ANTENNA_SCALE * SCALE_HP
      cy.scale[2] = ANTENNA_SCALE * SCALE_HP
      sw.scale[0] *= SCALE_HP
      sw.scale[1] *= SCALE_HP
      sw.scale[2] *= SCALE_HP
      quat.mul(n.rotation, n.rotation, Q)
      return n
    })

    //Adding cocktails hotspots
    this.cocktails = content_state.cocktails.map((c, i) => {
      const n:GltfNode = new GltfNode()
      vec3.copy(n.position, polarToCartesian((-c.latlng[1]) - 90, c.latlng[0], HOTSPOT_HEIGHT))
      n.invalidate()

      const name1:string = c.type === contentTypes.ARTICLE ? "Cylinder_1" : "Cylinder_tri_1"
      const name2:string = c.type === contentTypes.ARTICLE ? "Cylinder" : "Cylinder_tri"
      const cy = this.duplicateGltfNode(name1, n)
      const sw = this.duplicateGltfNode(name2, n)
      cy.scale[0] = ANTENNA_SCALE
      cy.scale[2] = ANTENNA_SCALE
      sw.scale[0] *= SCALE_HP
      sw.scale[1] *= SCALE_HP
      sw.scale[2] *= SCALE_HP
      this.gltfModule.gltf.nodes.push(sw, cy)
      n.invalidate()
      n.lookAt(GLOBE_MIDDLE)
      n.name = contentTypes.COCKTAIL + i
      globeNode.add(n)
      n.extras = { id: c.hotspot_id  }
      quat.mul(n.rotation, n.rotation, Q)
      return n
    })

    this.hotspotLights.init(globeNode)

    await this.gltfModule.gltf.allocateGl(this.scene.gl);

    const material:Material = this.gltfModule.gltf.getElementByName(GltfTypes.MATERIAL, "GoldMatTrans") as Material
      this.ringAlpha = new RingAlpha();
      const tCoord:TexCoord = TexCoord.create('aTexCoord0' )
      material.materialPass.inputs.add(tCoord)
      material.materialPass.inputs.add(this.ringAlpha)
      this.ringAlpha.progress = 0

    this.imageBasedLight = this.gltfModule.gltf.extras.lightsImageBased.list[0].ibl
    this.gltfModule.lightSetup.add(this.imageBasedLight);
    this.gltfModule.computeBounds()
    this.gltfModule.setupMaterials()

    this.isLoaded = true
  }

  duplicateGltfNode(name:string, n:GltfNode):GltfNode {
    const toDuplicate:GltfNode = this.gltfModule.gltf.nodes.filter((mr:GltfNode) => mr.name === name)[0] as GltfNode
    const dupNode:GltfNode = new GltfNode()
    dupNode.mesh = toDuplicate.mesh
    dupNode.name = toDuplicate.name
    dupNode.camera = toDuplicate.camera
    this.copyNode(toDuplicate, dupNode)
    n.add(dupNode)
    this.gltfModule.gltf.addElement(dupNode)
    return dupNode
  }

  duplicateNode(n:Node) {
    const d = new Node()
    this.copyNode(n, d)
    return d
  }

  copyNode(n:Node, d:Node) {
    mat4.copy(d._wmatrix, n._wmatrix)
    mat4.copy(d._matrix, n._matrix)
  }

  preRender() {
    if(!this.isLoaded) return
    this.hotspotLights.preRender()
  }

  rttRender(){
    if(!this.isLoaded) return
    this.gltfModule.rttRender()
  }

  render(){
    if(!this.isLoaded) return
    this.gltfModule.render()
    this.hotspotLights.render()
  }



  // setup material blending based on name
  //
  setupMaterials() {

    // const mats = this.gltfModule.gltf.materials
    // const gl = this.scene.gl

    // for (const m of mats) {

    //   if( m.name.includes('additive')){

    //     const pass = (m as Material).materialPass as StandardPass
    //     pass.mask = Masks.BLENDED
    //     pass.glconfig
    //       .enableBlend()
    //       .blendFunc(gl.ONE, gl.ONE)

    //   }

    //   if( m.name.includes('multiply')){

    //     const pass = (m as Material).materialPass as StandardPass
    //     pass.mask = Masks.BLENDED
    //     pass.glconfig
    //       .enableBlend()
    //       .blendFunc(gl.ZERO, gl.SRC_COLOR)

    //   }

    //   if( m.name.includes('doubleside')){
    //     const pass = (m as Material).materialPass as StandardPass
    //     pass.glconfig
    //     .enableCullface(false)

    //   }
    // }
  }

}