




























import Vue from "vue";
import Component from "vue-class-component";
import { gsap, Quart } from "gsap";

import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Intro extends Vue {
  // Datas
  timeline: any;
  hoverTimeline: any;

  // Props
  @Prop({ type: String, required: true }) label: string;
  @Prop({ type: String, required: false }) href: string;

  // Lifecycle
  mounted() {}

  // Transitions
  transitionIn(opts = { delay: 0, cb: () => {} }) {
    const $label: HTMLElement = this.$refs.button as HTMLElement;
    const $underline: HTMLElement = $label.querySelector("span") as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
    });

    this.timeline
      .fromTo(
        $label,
        {
          opacity: 0,
          y: "1rem",
        },
        {
          duration: 1,
          opacity: 1,
          y: "0",
        },
        0
      )
      .fromTo(
        $underline,
        {
          transformOrigin: "left",
          scaleX: 0,
        },
        {
          duration: 1,
          scaleX: 1,
        },
        0.2
      );

    // console.log($label)

    return this.timeline;
  }

  transitionOut(opts = { delay: 0, cb: () => {} }) {
    const $label: HTMLElement = this.$refs.button as HTMLElement;
    const $underline: HTMLElement = $label.querySelector("span") as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: () => {
        opts.cb();
      },
      delay: opts.delay,
    });

    this.timeline
      .to(
        $label,
        {
          duration: 1,
          opacity: 0,
          y: "1rem",
        },
        0
      )
      .to(
        $underline,
        {
          duration: 1,
          transformOrigin: "left",
          scaleX: 0,
        },
        0
      );
  }

  // Methods
  bindEvents() {}

  unbindEvents() {}

  // Events
  onClick() {
    this.$emit("click");
  }

  onMouseHover() {
    const $label: HTMLElement = this.$refs.button as HTMLElement;
    const $underline: HTMLElement = $label.querySelector("span") as HTMLElement;
    if (this.hoverTimeline) this.hoverTimeline.kill();
    this.hoverTimeline = gsap.timeline({});

    this.hoverTimeline
      .to(
        $underline,
        {
          duration: 0.7,
          transformOrigin: "right",
          scaleX: 0,
          ease: Quart.easeInOut,
        },
        0
      )
      .to($underline, {
        duration: 0.7,
        transformOrigin: "left",
        scaleX: 1,
        ease: Quart.easeInOut,
      });
  }

  onMouseLeave() {
    // const $label:HTMLElement = this.$refs.button as HTMLElement
    // const $underline:HTMLElement = $label.querySelector('span') as HTMLElement
    // if (this.hoverTimeline) this.hoverTimeline.kill()
    // this.hoverTimeline = gsap.timeline({
    // })
    // this.hoverTimeline
    //   .to($underline, {
    //     duration: 0.4,
    //     transformOrigin: 'left',
    //     scaleX: 1
    //   }, 0)
  }
}
