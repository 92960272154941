





















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { gsap, Quart } from "gsap";

@Component
export default class CocktailCard extends Vue {
  // Datas
  timeline: any;

  // Props
  @Prop({ type: Number, required: true }) idx: number;
  @Prop({ type: Object, required: true }) content: object;

  // Lifecycle
  mounted() {}

  // Transitions
  transitionIn(opts = { delay: 0, cb: () => {}, dur: 0 }) {
    const $card: HTMLElement = this.$refs.card as HTMLElement;
    const $container: HTMLElement = this.$refs.container as HTMLElement;
    const $separator: HTMLElement = this.$refs.separator as HTMLElement;
    const $city: HTMLElement = this.$refs.city as HTMLElement;
    const $title: HTMLElement = this.$refs.title as HTMLElement;
    const $team: HTMLElement = this.$refs.team as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
      duration: opts.dur,
    });

    this.timeline
      .fromTo(
        $card,
        {
          x: "100%",
        },
        {
          duration: opts.dur,
          x: 0,
          ease: "power2.out",
        },
        0
      )
      .fromTo(
        $container,
        {
          x: "-100%",
        },
        {
          duration: opts.dur,
          x: 0,
          ease: "power2.out",
        },
        0
      )
      .fromTo(
        $separator,
        {
          transformOrigin: "left",
          scaleX: "0",
        },
        {
          duration: opts.dur / 2,
          scaleX: 1,
          ease: "power2.out",
        },
        opts.dur / 2
      )
      .fromTo(
        [$city, $title, $team],
        {
          y: "1rem",
          opacity: 0,
        },
        {
          duration: opts.dur / 2,
          opacity: 1,
          y: 0,
          stagger: 0.05,
          ease: "power2.out",
        },
        opts.dur / 2
      );

    return this.timeline;
  }

  transitionOut(opts = { delay: 0, cb: () => {}, dur: 0 }) {
    const $card: HTMLElement = this.$refs.card as HTMLElement;
    const $container: HTMLElement = this.$refs.container as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
      duration: opts.dur,
    });

    this.timeline
      .to(
        $card,
        {
          duration: opts.dur,
          x: "100%",
        },
        0
      )
      .to(
        $container,
        {
          duration: opts.dur,
          x: "-100%",
        },
        0
      );

    return this.timeline;
  }

  // Methods
  // Events
}
