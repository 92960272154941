
let dt = 1/60
let time = -1



const Time = {

  get dt(){
    return dt
  },

  enterFrame(){
    const now = performance.now()
    const delta = now - time
    
    if( time > 0 ){
      dt = delta/1000
      dt = Math.min( 1/5, dt )
    }

    time = now
  }

}

export default Time



export class FpsSampler {


  previousTime = -1
  frames: number[] = []

  private _fps = 60
  private _invalid = false

  constructor( private numFrames = 15 ){}

  get fps(){
    if( this._invalid ){
      this._fps = this.getAverageFps()
    }
    return this._fps

  }

  sampleFrame(){
    
    const time = performance.now();

    if (this.previousTime === -1){
      this.previousTime = time
      return
    }

    const dt = (time - this.previousTime) / 1000;

    
    this.previousTime = time;

    // skip very long frame du to browser losing focus/display and pause raf
    if( dt < 3 ){
      this.frames.push(dt)
      while (this.frames.length > this.numFrames) {
        this.frames.shift()
      }
    }


    this._invalid = true

  }

  private getAverageFps(): number {

    if( this.frames.length < this.numFrames ){
      return 60
    }

    let t = 0
    for (let i = 0; i < this.frames.length; i++) {
      t += this.frames[i];
    }
    t /= this.frames.length

    return 1/t;
  }

}