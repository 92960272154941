
/////////////
/////////////////////////////////////////
//////////


import { DEG2RAD } from "@/webgl/math";
import Scene from "@/webgl/Scene";
import { mat4 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import CameraControler from './CameraController';
import MaxController from './MaxController';

// import browser from 'browser-detect'

// const browserInfo = browser()


export default class CamerasManager {

  mainCamera   : Camera<PerspectiveLens>
  devCamera  : Camera<PerspectiveLens>

  mainCamCtrl   : CameraControler
  devCamCtrl  : CameraControler

  maxcam    : MaxController

  useDebugCam    : boolean = false
  
  debugCam    : () => void
  mainCam     : () => void
  logDebugCam : () => void

  focalDist = 15


  constructor(readonly scene:Scene ){


    // CAMERA
    // ======
    this.mainCamera   = this.makeCamera()
    this.devCamera    = this.makeCamera()

    // CONTROLERS
    // ======
    this.devCamCtrl  = new CameraControler (scene , this.devCamera  )
    this.mainCamCtrl   = new CameraControler (scene , this.mainCamera   )

    this.maxcam    = new MaxController(scene.ilayer)


    // GRAPH
    // ======
    scene.root.add( this.mainCamera )
    scene.root.add( this.devCamera  )


    this.devCamCtrl.setControler(this.maxcam);

    
/////////////////
////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/////////////////////////////
////////////////////////////////
////////////////////////////

/////////////////////////////////////////////////
/////////////////////////////////////////////////
////
////
//////////////////////////////////////////////////
////


//////////////

    // if( !browserInfo.mobile){
    //   this.useDebugCam = true 
    // }

  }

  /**
   * the rendered camera
   */
  get camera()     : Camera<PerspectiveLens>{
    return this.useDebugCam? this.devCamera : this.mainCamera;
  }

  get controler()     : CameraControler{
    return this.useDebugCam? this.devCamCtrl : this.mainCamCtrl;
  }


  preRender(){
    this.controler.preRender()
  }


  makeCamera() {
    // const camera = Camera.makePerspectiveCamera()
    const camera = new Camera( new PerspectiveLens() );
    camera.lens.setAutoFov(35.0 * DEG2RAD) //80
    camera.lens.near = .01
    camera.lens.far = 50

    camera.setMatrix(new Float32Array(
      [0.7726250290870667, -1.4619167210128126e-8, -0.6348624229431152, 0, -0.030748367309570312, 0.9988264441490173, -0.037420663982629776, 0, 0.6341174244880676, 0.048433128744363785, 0.7717183828353882, 0, 5.253443717956543, 1.3910399675369263, 6.792383193969727, 1]
    ) as mat4)

    return camera
  }


  makeBubbleCamera(){

    const camera = Camera.makeOrthoCamera()
    camera.lens.near = 1
    camera.lens.far = 50

    return camera
  }
  

}