import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class Fps extends VuexModule {
  
  public value = 0

  @Action
  setValue( v:number ) {
    this._SET(v)
  }

  @Mutation
  private _SET(v:number) {
    this.value = v
  }
}

const fps = new Fps({ store, name: 'fps' })

export default fps
