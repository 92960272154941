





































import Vue from 'vue';
import gsap from 'gsap'
import Component from 'vue-class-component';

import Lottie from "../Lottie/Lottie.vue";
import globe_state from '@/store/modules/globe_state';

import Close from "!vue-svg-loader!@/assets/icons/close.svg";

@Component({
  components: { Close, Lottie },
})
export default class Loader extends Vue {
  timeline: any

  mounted () {
    this.transitionIn();

    (this.$refs.tuto1 as Lottie).play();
    (this.$refs.tuto2 as Lottie).play();
  }

  // Transitions
  transitionIn() {
    const $wrapper: HTMLElement = this.$refs.wrapper as HTMLElement;
    const $close: SVGSVGElement = this.$refs.close as SVGSVGElement;
    const $button: HTMLElement = this.$refs.closeButton as HTMLElement;
    const $closeText: HTMLElement = this.$refs.closeText as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({ delay: 1 });

    this.timeline
      .to($wrapper, {
        duration: 1,
        opacity: 1,
        ease: "linear",
      }, 0)
      .to($close, {
        duration: 0.5,
        scale: 1,
        ease: "power2.out",
      }, 0)
      .to(
        $button,
        {
          duration: 0.5,
          scale: 1,
          ease: "power2.out",
        },
        0.1
      )
      .to(
        $closeText,
        {
          duration: 0.5,
          opacity: 1,
          ease: "linear",
        },
        0.3
      );
  }

  close() {
    globe_state.setIsTuto(false)
  }

  transitionOut(_, done) {
    const $wrapper: HTMLElement = this.$refs.wrapper as HTMLElement;
    const $close: SVGSVGElement = this.$refs.close as SVGSVGElement;
    const $button: HTMLElement = this.$refs.closeButton as HTMLElement;
    const $closeText: HTMLElement = this.$refs.closeText as HTMLElement;

    this.timeline = gsap.timeline({
      delay: 0.5,
      onComplete: () => {
        done()
      },
    })

    this.timeline
      .to(
        $wrapper,
        {
          duration: 0.5,
          opacity: 0,
          ease: "linear",
        },
        0
      )
      .to(
        $button,
        {
          duration: 0.5,
          scale: 0,
          ease: "power2.out",
        },
        0
      )
      .to(
        $close,
        {
          duration: 0.5,
          scale: 0,
          ease: "power2.out",
        },
        0
      )
      .to(
        $closeText,
        {
          duration: 0.5,
          opacity: 0,
          ease: "linear",
        },
        0
      );
  }
}
