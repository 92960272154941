import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import content_state, { contentTypes } from './content_state'


@Module
export class GlobeState extends VuexModule {

  // ====================================================== When we can play globe intro animation

  public introStart: boolean = false

  @Action
  setIntroStart() {
    this._SET_INTRO_START()
  }

  @Mutation
  private _SET_INTRO_START() {
    this.introStart = true
  }

  // ====================================================== When globe intro animation is over

  public introDone: boolean = false

  @Action
  setIntroDone() {
    this._SET_INTRO_DONE()
  }

  @Mutation
  private _SET_INTRO_DONE() {
    this.introDone = true
  }

  // ====================================================== When loader is visible

  public isLoading: boolean = false

  @Action
  setIsLoading(b:boolean) {
    this._SET_IS_LOADING(b)
  }

  @Mutation
  private _SET_IS_LOADING(b:boolean) {
    this.isLoading = b
  }

  // ====================================================== When loader is visible

  public preload: number = 0

  @Action
  setPreload(n:number) {
    this._SET_PRELOAD(n)
  }

  @Mutation
  private _SET_PRELOAD(n:number) {
    this.preload += n
  }

  // ====================================================== When globe tuto is playing

  public isTuto: boolean = false

  @Action
  setIsTuto(b:boolean) {
    this._SET_IS_TUTO(b)
  }

  @Mutation
  private _SET_IS_TUTO(b:boolean) {
    this.isTuto = b
  }

  // ====================================================== Has user rotated globe

  public hasRotated: boolean = false

  @Action
  setHasRotated() {
    this._SET_HAS_ROTATED()
  }

  @Mutation
  private _SET_HAS_ROTATED() {
    this.hasRotated = true
  }

  // ====================================================== currentHotspot Open

  public currentHotSpot: number = -1

  @Action
  setCurrentHotSpot( id:number ) {
    this._SET_CURRENT_HOTSPOT(id)
  }

  @Mutation
  private _SET_CURRENT_HOTSPOT(id:number) {
    this.currentHotSpot = id
  }

  // ====================================================== currentHotspot Open

  public rollHotSpot: number = -1

  @Action
  setRollHotSpot( id:number ) {
    this._SET_ROLL_HOTSPOT(id)
  }

  @Mutation
  private _SET_ROLL_HOTSPOT(id:number) {
    this.rollHotSpot = id
  }

  // ====================================================== currentHotspot Open

  public currentId: string = ""

  @Action
  setCurrentId( id:string ) {
    this._SET_CURRENT_ID(id)
  }

  @Mutation
  private _SET_CURRENT_ID(id:string) {
    content_state.setContent(id)
    this.currentId = id
  }


  // ====================================================== currentContent Open

  public currentCtn: number = -1

  @Action
  setCurrentCtn( id:number ) {
    this._SET_CURRENT_CTN(id)
  }

  @Mutation
  private _SET_CURRENT_CTN(id:number) {
    this.currentCtn = id
  }


  // ====================================================== Current mode

  public globeMode: contentTypes = contentTypes.ARTICLE

  @Action
  setGlobeMode( mode:contentTypes ) {
    this._SET_GLOBE_MODE(mode)
  }

  @Mutation
  private _SET_GLOBE_MODE(mode:contentTypes) {
    this.globeMode = mode
  }


  // ====================================================== Current mode

  public isAllExperiences: boolean = false

  @Action
  setIsAllExperiences( isAllExperiences: boolean ) {
    this._SET_IS_ALL_EXPERIENCE(isAllExperiences)
  }

  @Mutation
  private _SET_IS_ALL_EXPERIENCE(isAllExperiences: boolean) {
    this.isAllExperiences = isAllExperiences
  }


  // ====================================================== Article hover

  public rollCard:number = 0

  @Action
  setRollCard( id:number ) {
    this._SET_ROLL_CARD(id)
  }

  @Mutation
  private _SET_ROLL_CARD(id:number) {
    this.rollCard = id
  }
}

const globe_state = new GlobeState({ store, name: 'globe_state' })

export default globe_state