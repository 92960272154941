import gsap from 'gsap'
import Rect from "nanogl-primitives-2d/rect"
import Config  from 'nanogl-state/config'
import Program from "nanogl/program"
import { vec3 } from "gl-matrix"

import vert from "@/webgl/glsl/hotspotLight/hotspotLight.vert"
import frag from "@/webgl/glsl/hotspotLight/hotspotLight.frag"
import Delay from '@/core/Delay'
import Scene from "@/webgl/Scene"
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import globe_state from "@/store/modules/globe_state";
import content_state from "@/store/modules/content_state"
import { GLOBE_MIDDLE } from "./GlobeModel"
import { polarToCartesian } from "@/webgl/math/PolarCart"

const LIGHT_HEIGHT = 1
const LIGHT_SCALE = 0.2

class HotspotLight {
  node: GltfNode
  introDone = false
  hoverFactor = 0
  hiddenFactor = 1
  currentHover = false
  currentHidden = true

  constructor(private id: number, private position: vec3, private parent: HotspotLights) {
    this.node = new GltfNode()

    vec3.copy(this.node.position, this.position)
    this.node.lookAt(GLOBE_MIDDLE)
    this.node.setScale(LIGHT_SCALE)

    this.parent.globeNode.add(this.node)
    this.node.invalidate()
  }

  get isHidden(): boolean {
    return (globe_state.rollHotSpot !== -1 && !this.isHover)
  }

  get isHover(): boolean {
    return globe_state.rollHotSpot === this.id
  }

  show() {
    this.currentHidden = false
    gsap.to(this, {
      hiddenFactor: 0,
      duration: 1,
      ease: 'Power1.easeInOut',
      onComplete: () => {
        this.introDone = true
      }
    })
  }

  preRender() {
    if (this.introDone === false) return

    if (this.isHover !== this.currentHover) {
      this.currentHover = this.isHover
      gsap.to(this, {
        hoverFactor: this.isHover ? 1 : 0,
        duration: 0.5,
        ease: 'Power1.easeOut'
      })
    }

    if (this.isHidden !== this.currentHidden) {
      this.currentHidden = this.isHidden
      gsap.to(this, {
        hiddenFactor: this.isHidden ? 1 : 0,
        duration: 0.5,
        ease: 'Power1.easeOut'
      })
    }
  }

  render(scene: Scene, quad: Rect, prg: Program) {
    prg.uMVP(scene.camera.getMVP(this.node._wmatrix))
    prg.uHoverFactor(this.hoverFactor)
    prg.uHiddenFactor(this.hiddenFactor)
    quad.render()
  }
}

export default class HotspotLights {
  cfg: Config
  prg: Program
  quad: Rect
  lights: HotspotLight[] = []
  globeNode: GltfNode

  constructor(private scene: Scene) {
    this.quad = new Rect(this.scene.gl)

    this.prg = new Program(scene.gl, vert(), frag())

    this.cfg = new Config()
      .depthMask(false)
      .enableDepthTest(true)
      .enableBlend(true)
      .blendFunc(scene.gl.SRC_ALPHA, scene.gl.ONE)

    globe_state.$watch(
      (s) => s.isTuto,
      (v) => {
        this.onTutoUpdate()
      }
    )
  }

  init(globeNode: GltfNode) {
    this.globeNode = globeNode

    for (let i = 0; i < content_state.articles.length; i++) {
      const hotspot = content_state.articles[i]
      const light = new HotspotLight(i, polarToCartesian((-hotspot.latlng[1]) - 90, hotspot.latlng[0], LIGHT_HEIGHT), this)
      this.lights.push(light)
    }
  }

  preRender() {
    for(let i = 0; i < this.lights.length; i++){
      const light = this.lights[i]
      light.preRender()
    }
  }

  render(){
    if (this.lights.length === 0) return

    this.prg.use()

    this.scene.glstate.push(this.cfg)
    this.scene.glstate.apply()

    this.quad.attribPointer(this.prg)

    for(let i = 0; i < this.lights.length; i++){
      const light = this.lights[i]
      light.render(this.scene, this.quad, this.prg)
    }

    this.scene.glstate.pop()
  }

  async onTutoUpdate() {
    if (globe_state.introDone && !globe_state.isTuto) {
      await Delay(2000)

      for(let i = 0; i < this.lights.length; i++){
        const light = this.lights[i]
        light.show()
      }
    }
  }
}