import { vec3 } from "gl-matrix"

var RAD2DEG = 180 / Math.PI
var DEG2RAD = Math.PI / 180
var TEMP_VEC:vec3 = vec3.create()

/**
 * Convert [lat,lon] polar coordinates to [x,y,z] cartesian coordinates
 * @param {Number} lon
 * @param {Number} lat
 * @param {Number} radius
 * @return {Vector3}
 */
export function polarToCartesian( lon:number, lat:number, radius:number ):vec3 {

  var phi = ( 90 - lat ) * DEG2RAD
  var theta = ( lon + 180 ) * DEG2RAD

  vec3.set(
    TEMP_VEC,
    -(radius * Math.sin(phi) * Math.sin(theta)),
    radius * Math.cos(phi),
    radius * Math.sin(phi) * Math.cos(theta),
  )
  return TEMP_VEC
}

/**
 * Convert [x,y,z] cartesian coordinates to polar [lat,lon]
 * @param {Vector3} coord
 * @return {Array<Number>}
 */
export function cartesianToPolar( coord:vec3 ):vec3 {
    var lon = Math.atan2( coord[0], -coord[2] ) * RAD2DEG
    var length = Math.sqrt( coord[0] * coord[0] + coord[2] * coord[2] )
    var lat = Math.atan2( coord[1], length ) * RAD2DEG
    vec3.set(TEMP_VEC, lon, lat, length)
    return TEMP_VEC
}