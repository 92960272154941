













import { hotspotPositions } from "@/common/webgl-vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Hotspot from "./Hotspot.vue";

@Component({
  components: { Hotspot },
})
export default class Hotspots extends Vue {
  hpPositionsNB: number = 0;

  @Prop({
    type: Boolean,
  })
  isPlaying: boolean;

  preRender() {
    if (this.hpPositionsNB === 0 && hotspotPositions.length > 0) {
      this.hpPositionsNB = hotspotPositions.length;
      return;
    }

    for (let i = 0; i < hotspotPositions.length; i++) {
      const hp = this.$refs.hps as any;
      hp[i].preRender(hotspotPositions[i]);
    }
  }

  onHotspotClicked() {
    this.$emit('onHotspotClicked')
  }
}
