
/////////////
//////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////

/////////////////////////////////////////////////////////
///////////
//////////


import   GLState           from 'nanogl-state'
import   Node              from 'nanogl-node'
import   Camera            from 'nanogl-camera'
import   Rect              from 'nanogl-primitives-2d/rect'
import { GLContext       } from 'nanogl/types'

import Programs         from '@/webgl/gl/Programs'
import Inputs           from '@/webgl/lib/inputs'
import GLView           from '@/webgl/GLView'

import Deferred from '@/core/Deferred'
import ResourceGroup from './assets/ResourceGroup'
import CamerasManager from './camera/CamerasManager'
import Rect2D from './entities/Rect2D'
import Fbo from 'nanogl/fbo'
import IModule from './IModule'
// import EVModule from './EVModule';
import { mat4, quat } from 'gl-matrix';
import Time, { FpsSampler } from './Time';
import PostProcess from './gl/post';
import fps from "@/store/modules/fps";

const GREY_REF:number = 244/255

export default class Scene {
  
  dt        : number
  time      : number
  aspect    : number

  ilayer    : HTMLElement
  loaddefer : Deferred
  glview    : GLView
  gl        : GLContext
  root      : Node
  glstate   : GLState
  quad      : Rect
  rect2d    : Rect2D
  inputs    : Inputs
  programs  : Programs
  cameras   : CamerasManager
  post      : PostProcess
  // iblMngr   : IBLManager
  module    : IModule
  fpsSampler: FpsSampler
  // soundtrack  : Soundtrack


  
  enableDebugDraw: boolean
  envRotation: number;
  readonly invRootMatrix = mat4.create()
  
  norender = false


  constructor() {
    this.dt     = 0   
    this.time   = 0   
    this.aspect = 1.0 
    this.ilayer = null
    this.fpsSampler = new FpsSampler(10)
  }

  get camera(){
    return this.cameras.camera
  }

  get mainCamera(){
    return this.cameras.mainCamera
  }

  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview : GLView, module: IModule ) {

    this.loaddefer = new Deferred()

    this.glview = glview
    this.gl = this.glview.gl

    this.ilayer = this.glview.gl.canvas as HTMLElement


    this.root  = new Node();

    this.glstate     = new GLState (this.gl    );
    this.programs    = new Programs(this);
    this.quad        = new Rect    (this.gl    );
    this.rect2d      = new Rect2D  (this)
    this.inputs      = new Inputs  (this.ilayer);
    this.cameras     = new CamerasManager( this )
    this.module      = module;//new EVModule()
    this.post        = new PostProcess(this)
    this.module.init( this )
    

    this.envRotation = 0
    
/////////////////
/////////////////////////
////////////////////////////////
//////////////


    this.inputs.start();


/////////////////

///////////////
////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////
//////////////////////////////////////////////////

//////////////

  }




  handleResize(){0
  }


 
  render(dt:number) {
    Time.enterFrame()
    
    
/////////////////
/////////////////////////////////////
///////////////
////////
////////////////////////////

//////////////
    
    this.dt = dt;
    this.time += dt;

    this.drawScene( this.camera );

  }



  drawScene( camera : Camera, fbo:Fbo = null){
    
    
    const gl = this.gl;
    const w = fbo ? fbo.width  : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;
    
    this.aspect = w / h;
    
    
    // preRender
    // =============
    // this.layers   .preRender()
    this.cameras  .preRender()
    this.module .preRender()
    
    

    // if( router.currentRoute.name === RouteName.TRAINS )
      // this.bubble.preRender()


      


    // upadate graph
    // =================

    quat.identity(this.root.rotation)
    this.root.rotateY( this.envRotation );
    this.root.updateWorldMatrix()
    // if(this.root._children.length >= 3) {
    //   this.root._children[2].rotation.set( [0,0,0,1] );
    //   this.root._children[2].rotateY( -this.envRotation );
    //   this.root._children[2].updateWorldMatrix()
    // }
    
    mat4.invert( this.invRootMatrix, this.root._wmatrix )
    camera.updateViewProjectionMatrix(w, h);

    
    // RTT
    // ==========

    // bg halo rendered in a lowres fbo
    
    if( !this.norender)
      this.module.rttRender()
    
    // low res layers (bokeh)
    // this.layers.prepareLowResAdditive();

    
  
    // RENDER
    // ========
    
    this.glstate.apply();
    gl.clearColor(GREY_REF, GREY_REF, GREY_REF, 1);
    
    this.post.post.preRender(w, h);
    this.post.post.bindColor()
    
    // gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    // gl.viewport( 0, 0, w, h );
    // gl.clear( gl.DEPTH_BUFFER_BIT );
    
    // this.blitArCamera()
    // gl.enable( gl.SCISSOR_TEST );
    // gl.scissor( 40, 40, w-80, h-80 )
    
    //render stuffs
    if( !this.norender)
      this.module.render()

    // TODO: quick and dirty switch
    // if( router.currentRoute.name === RouteName.STORY )
    //   this.bubble.render()

    // this.layers.render()

    
    
    this.glstate.apply()
    this.post.post.render()
    
    this.fpsSampler.sampleFrame()

    const fpsVal = Math.round(this.fpsSampler.fps)
    fps.setValue(fpsVal)
    // gl.disable( gl.SCISSOR_TEST );
    
    
/////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
///////////////////////////////
//////////////////////////////
////////////////////////
/////
//////////////////////
//////////////


/////////////////
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////////

  }


  // blitArCamera(){
    
  //   const prg = this.programs.get( 'blit' )
  //   prg.use()
  //   // prg.tTex( this.arview.fbo.getColorTexture() )
  //   // prg.tTex( this.arview.cameraTexture)
  //   this.quad.attribPointer(prg)
  //   this.quad.render()
  // }



  async load() {
    // ResourceGroup.default.add( this.musee.getResources() )
    await this.module.load()
    await this.compileShaders()
    await ResourceGroup.default.load()

    this.onLoaded()
    this.post.init()
  }



  compileShaders = () => {
    this.programs.compile()
    return Promise.resolve()
  }


  onLoaded = () => {
    0
  }



}




