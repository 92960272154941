// import IBLManager from "@/webgl/gl/IBLManager";
import globe_state from "@/store/modules/globe_state";
import CameraControler from "@/webgl/camera/CameraController";
import Scene from "@/webgl/Scene";
import { quat, vec3 } from "gl-matrix";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import Node from "nanogl-node";
import GlobeModel from "./GlobeModel";
import { assetURL, hotspotPositions, SCALE_HP } from "@/common/webgl-vue";
import content_state, { Article, contentTypes } from "@/store/modules/content_state";
import { cartesianToPolar, polarToCartesian } from "@/webgl/math/PolarCart";
import gsap, { Cubic, Expo, Power1 } from "gsap";
import ResourceGroup from "@/webgl/assets/ResourceGroup";
import { ArrayBufferResource } from "@/webgl/assets/ArrayBufferResource";
import CinemachinePath, { Waypoint } from "@/webgl/lib/cinemachine/CinemachinePath";
import { PositionUnits } from "@/webgl/lib/cinemachine/CinemachinePathBase";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { getInURL } from "@/core/UrlParams";
import RotationController from "@/webgl/camera/RotationController";
import quatFromUnitVectors from "@/webgl/math/quat-fromUnitVectors";
import { clamp } from "@/webgl/math";
import Delay from "@/core/Delay";

/////////////
/////////////////////////////////

///////////////////////////////////////////////////
///////////
//////////



const Q1 = quat.create()
const Q2 = quat.create()
const Q3 = quat.create()

const V3A = vec3.create()
const V3A2 = vec3.create()
const V3A3 = vec3.create()

const GLOBE_MIDDLE = vec3.create()
vec3.set(GLOBE_MIDDLE, 0, 0, 0)

const TARGET = vec3.create()
vec3.set(TARGET, 0, 0, 0)

const BACK = vec3.create()
vec3.set(BACK, 0, 0, -0.1)

const UP = vec3.fromValues(0, 1, 0)

const CAM_DEFAULT_Z = 4.5
let CAM_ROLL_Z = 3
let CAM_ZOOM_Z = 2.5

let SCALE_WHILE_ZOOM = 0.5

// const CAM_ROLL_Z = 2.5
// const CAM_ZOOM_Z = 2.25

const CAMERA_POS = vec3.fromValues(0, 0, CAM_DEFAULT_Z)

export default class GlobeController {
  private resources: ResourceGroup;
  readyToRender: boolean;
  get Resources(): ResourceGroup {
    return this.resources;
  }
  model: GlobeModel;
  // iblMngr: IBLManager;
  camCtrl: CameraControler;
  // orbitcam: OrbitController;
  objRotation: RotationController

  globeNode: GltfNode
  globeNodeWrapper: GltfNode
  insideGlobeNode: GltfNode

  path: CinemachinePath;
  isIntroMode: boolean


  latlng: vec3
  savePos: vec3
  saveGlobPos: vec3
  prevLookAt: vec3
  lookat: vec3

  _loadPromise: Promise<void> = null
  _active: boolean;
  _isAllExperience: boolean

  _listMode: boolean

  yChange: number

  tweenDest: gsap.core.Tween

  hotspotHover: GltfNode

  saveRotation: quat

  constructor(readonly scene: Scene) {

    this.savePos = vec3.create()
    this.prevLookAt = vec3.create()
    this.lookat = vec3.create()
    vec3.copy(this.lookat, GLOBE_MIDDLE)
    vec3.copy(this.prevLookAt, GLOBE_MIDDLE)
    this.saveGlobPos = vec3.create()
    this.latlng = vec3.create()

    this.saveRotation = quat.create()

    this.yChange = 0

    this.resources = new ResourceGroup();
    this.setupResources();
    this.model = new GlobeModel(scene)
  }

  setupResources() {
    this.resources.add(
      new ArrayBufferResource(assetURL(`assets/webgl/globe/intro.bin`)),
      "introPathcam"
    )
  }

  public load() {
    const proms = Promise.all([
      this.model.load(),
      this.resources.load()
    ])

    proms.then(() => {
      this.start()
      this.readyToRender = true
    })

    return proms
  }

  start() {

    CAM_ROLL_Z = content_state.isMobile ? 2.5 : CAM_ROLL_Z
    CAM_ZOOM_Z = content_state.isMobile ? 2 : CAM_ZOOM_Z

    this.globeNode = this.model.gltfModule.gltf.getElementByName(GltfTypes.NODE, "globe-wrapper")
    this.insideGlobeNode = this.model.gltfModule.gltf.getElementByName(GltfTypes.NODE, "globe")
    this.globeNodeWrapper = this.model.gltfModule.gltf.getElementByName(GltfTypes.NODE, "globe-container")
    this.setPathData(this.resources.get("introPathcam"))
    this.camCtrl = new CameraControler(this.scene, this.scene.camera)
    this.objRotation = new RotationController(this.scene.ilayer, {
      dist: 5,
      minDist: 5,
      maxDist: 5,
      rotateSpeed: 10,
      wheelSpeed: .15,
      dragSpeed: 15,
      center: vec3.fromValues(0.0, 0, 0.0),
      minPhi: -Math.PI * 0.05,
      maxPhi: Math.PI * 0.05,
      _phi: 0,
      _theta: Math.PI
    });
    this.camCtrl.setControler(null)

    globe_state.$watch(s => s.isLoading, (newval) => this.checkLaunchIntro())
    globe_state.$watch(s => s.introStart, (newval) => this.checkLaunchIntro())
    globe_state.$watch(s => s.isTuto, (newval) => this.showHotspots())
    globe_state.$watch(s => s.currentHotSpot, (newval, prevVal) => this.setCurrentHotSpot(newval, prevVal, globe_state.globeMode === contentTypes.COCKTAIL ? this.model.cocktails : this.model.hotspots))
    globe_state.$watch(s => s.rollHotSpot, (newval) => this.rollHotspot(newval))
    globe_state.$watch(s => s.isAllExperiences, (newval) => this.setAllExperiences(newval))
    globe_state.$watch(s => s.globeMode, (newval) => this.setGlobeMode(newval))
    globe_state.$watch(s => s.rollCard, (newval) => this.setHotspotCenter(newval, true))
    // content_state.$watch( s=>s.contentMode, (newval)=> {
    //   this.setContentMode(newval)
    // })

    this.fillHotSpotPositions()
    let t: vec3 = this.path.EvaluatePositionAtUnit(1, PositionUnits.Normalized);
    this.scene.camera.lookAt(TARGET)

    this.placeCamIntro(0)

    this.model.root.setScale(this.model.root.scale[0] * 1.25)

/////////////////
///////////////
///////////////
/////
///////////////
////////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////
//////
//////////////////////////////////////////////////

//////////////
  }

  checkLaunchIntro() {
    if (!globe_state.isLoading && globe_state.introStart) this.startIntro()
  }

  setPathData(data: ArrayBuffer) {
    this.path = CinemachinePath.CreateFromArrayBuffer(data, 50);
    this.path.m_Waypoints.push(
      new Waypoint(
        vec3.fromValues(0, 0, 4.5),
        vec3.fromValues(0, 0, 0),
        0
      )
    )
  }

  async startIntro() {
    this.objRotation.start(this.globeNode)

    if (getInURL("skipIntro") === null) {

      const intro = {
        p: 0
      }
      for (let i = 0; i < content_state.articles.length; i++) {
        const hp = this.model.hotspots[i];
        hp.scale[0] = 0.01
        hp.scale[1] = 0.01
        hp.scale[2] = 0.01
        hp.invalidate()
      }
      const rot = {
        v: -Math.PI
      }

      gsap.to(intro, {
        duration: 2,
        ease: Power1.easeOut,
        p: 1,
        onUpdate: () => this.placeCamIntro(intro.p)
      })

      gsap.to(this.model.ringAlpha, { duration: 1.5, progress: 1.1, ease: Power1.easeInOut, delay: 1 })

      await gsap.to(rot, {
        duration: 3, v: 0,
        onUpdate: () => {
          quat.setAxisAngle(this.globeNodeWrapper.rotation, [0, 1, 0], rot.v)
          this.globeNodeWrapper.invalidate()
        },
      })

      globe_state.setIntroDone()
    }

    this.model.gltfModule.renderArticles = true
    // this.model.ringAlpha.progress = 1.1
    vec3.copy(this.scene.camera.position, CAMERA_POS)
    this.scene.camera.lookAt(GLOBE_MIDDLE)
    this.objRotation.startGrab(this.globeNode)
  }

  showHotspots() {
    if (globe_state.isTuto) {
      this.toggleBlur(5)
      return
    }

    this.toggleBlur(0)
    for (let i = 0; i < content_state.articles.length; i++) {
      const hp = this.model.hotspots[i];
      hp.scale[0] = 0.01
      hp.scale[1] = 0.01
      hp.scale[2] = 0.01
      hp.invalidate()
      this.scaleHotspot(contentTypes.ARTICLE, i, 1, 1.5)
    }
  }

  placeCamIntro(p: number) {
    vec3.copy(this.scene.camera.position, this.path.EvaluatePositionAtUnit(p, PositionUnits.Normalized))
    this.scene.camera.invalidate()
    vec3.copy(V3A2, this.scene.camera.position)
    vec3.add(V3A2, V3A2, BACK)
    this.scene.camera.lookAt(V3A2)
  }

  fillHotSpotPositions() {
    const w = (this.scene.gl.canvas as HTMLElement).clientWidth
    const h = (this.scene.gl.canvas as HTMLElement).clientHeight
    for (let i = 0; i < this.model.hotspots.length; i++) {
      const node: GltfNode = this.model.hotspots[i] as GltfNode
      vec3.set(V3A, node._wmatrix[12], node._wmatrix[13], node._wmatrix[14])
      vec3.transformMat4(V3A2, V3A, this.scene.camera._viewProj)

      const x = (V3A2[0] + 1) / 2 * w
      const y = (-1 * V3A2[1] + 1) / 2 * h

      const dist = vec3.distance(this.scene.camera.position, V3A)
      hotspotPositions[i] = [x, y, dist > 5 ? 0 : 1]
    }
  }

  preRender() {
    if (this.readyToRender) {
      this.camCtrl.preRender();
      this.objRotation.update(this.scene.dt)
      this.fillHotSpotPositions();
      this.model.preRender()

      if (!globe_state.hasRotated && globe_state.introDone) {
        if (this.objRotation.currTuto > 0) {
          this.model.ringAlpha.start = Math.abs(this.objRotation.currTuto) * 1.1
          this.model.ringAlpha.progress = 1.1
        }
        else this.model.ringAlpha.progress = Math.max(0, 1.1 - Math.abs(this.objRotation.currTuto) * 1.1)
        if (Math.abs(this.objRotation.currTuto) === 1) globe_state.setHasRotated()
      }
    }
  }

  rttRender() {
    if (this.readyToRender) {
      this.model.rttRender()
      //   this.interaction.rttRender()
    }
  }

  render() {
    if (this.readyToRender) {
      this.model.render()
    }
  }

  setAllExperiences(isAllExperiences: boolean) {
    this.model.gltfModule.renderArticles = this.model.gltfModule.renderCocktails = true
    this._isAllExperience = isAllExperiences
    this._listMode = true
    if (isAllExperiences) {
      quat.copy(this.saveRotation, this.globeNode.rotation)
      this.objRotation.stop()
      for (let i = 0; i < this.model.cocktails.length; i++) {
        const hp = this.model.cocktails[i];
        hp.scale[0] = 0.01
        hp.scale[1] = 0.01
        hp.scale[2] = 0.01
        hp.invalidate()
        this.scaleHotspot(contentTypes.COCKTAIL, i, 1)
      }
      for (let i = 0; i < this.model.hotspots.length; i++) {
        if (this.model.hotspots[i].extras.isUS) {
          this.scaleHotspot(contentTypes.ARTICLE, i, 0.01)
          break;
        }
      }
      this.setHotspotCenter(0, false, false, true)
      this.toggleBlur(5)
    } else {
      this.setArticleMode()
      this.toggleBlur(0)
    }
  }

  async setGlobeMode(type: contentTypes) {
    if (type === contentTypes.COCKTAIL) {
      this.model.gltfModule.renderCocktails = true
      this.setCocktailMode()
    } else {
      this.model.gltfModule.renderArticles = true
      this.setArticleMode()
    }

    await Delay(2000)

    this.model.gltfModule.renderArticles = type === contentTypes.ARTICLE
    this.model.gltfModule.renderCocktails = type === contentTypes.COCKTAIL
  }

  setContentMode(type: contentTypes) {
    if (type === contentTypes.ARTICLE) {
      this.setArticleMode()
    }
  }

  async setCurrentHotSpot(id: number, prevId: number, list: Array<GltfNode>) {
    if (!this._listMode && id !== -1 && prevId === -1) {
      quat.copy(this.saveRotation, this.globeNode.rotation)
    }
    this.objRotation.stop()
    // this.camCtrl.setControler(null);
    if (id === -1) {
      if (globe_state.globeMode === contentTypes.ARTICLE && !this._isAllExperience) this.backToUnZoom()
      else if (!this._isAllExperience) {
        this.toggleBlur(0)
        this.setHotspotCenter(globe_state.currentHotSpot, true, true)
      }
    }
    else {
      // if(this._isAllExperience) vec3.copy(this.savePos, this.scene.camera.position)
      let listId = id
      let nodeList: GltfNode[] = list
      let type: contentTypes = globe_state.globeMode
      if (this._isAllExperience) {
        const list: Array<Article> = content_state.globContent[id].type === contentTypes.ARTICLE ? content_state.articles : content_state.cocktails
        listId = this.getFromRightList(content_state.globContent[id].latlng, list)

        type = content_state.globContent[id].type

        nodeList = content_state.globContent[id].type === contentTypes.ARTICLE ? this.model.hotspots : this.model.cocktails

        if (listId === -1) console.error("this id is doesnt exist in type", content_state.globContent[id].type)

        globe_state.setCurrentId(nodeList[listId].extras.id)
        globe_state.setCurrentCtn(listId)

        return
      }

      globe_state.setCurrentId(nodeList[listId].extras.id)
      if (!content_state.isMobile) globe_state.setCurrentCtn(listId)
      await this.zoomTo(listId, nodeList, type, CAM_ZOOM_Z, 20, true, globe_state.globeMode !== contentTypes.ARTICLE && !this._isAllExperience)
      if (content_state.isMobile) globe_state.setCurrentCtn(listId)
    }
  }

  rollHotspot(id: number) {
    if (id === -1) this.unroll()
    else this.roll(id)
  }

  roll(id: number) {
    this.hotspotHover = this.model.hotspots[id]
    if (!this.hotspotHover.extras.rotateY) {
      this.hotspotHover.extras.rotateY = 0
      this.hotspotHover.extras.scale = 1
    }
    const extra = this.hotspotHover.extras
    gsap.killTweensOf(this.hotspotHover.extras)
    gsap.to(extra, {
      duration: 0.5,
      rotateY: Math.PI / 2,
      scale: 1.15,
      ease: Cubic.easeOut,
      onUpdate: () => {
        const h = this.hotspotHover._children[1]
        quat.identity(h.rotation)
        quat.rotateY(h.rotation, h.rotation, extra.rotateY)
        vec3.set(h.scale, extra.scale * SCALE_HP, extra.scale * SCALE_HP, extra.scale * SCALE_HP)
        h.invalidate()
      }
    }
    )
  }

  unroll() {
    const extra = this.hotspotHover.extras
    const hp = this.hotspotHover
    gsap.killTweensOf(this.hotspotHover.extras)

    gsap.to(extra, {
      duration: 0.35,
      rotateY: 0,
      scale: 1,
      ease: Cubic.easeOut,
      onUpdate: () => {
        const h = hp._children[1]
        quat.identity(h.rotation)
        quat.rotateY(h.rotation, h.rotation, extra.rotateY)
        vec3.set(h.scale, extra.scale * SCALE_HP, extra.scale * SCALE_HP, extra.scale * SCALE_HP)
        h.invalidate()
      }
    }
    )

    this.hotspotHover = null
  }

  async backToUnZoom() {
    this.toggleBlur(0)
    for (let i = 0; i < content_state.articles.length; i++) {
      this.scaleHotspotAntenna(globe_state.globeMode, i, 1)
      this.scaleHotspot(globe_state.globeMode, i, 1)
    }
    await this.moveCameraTo(this.savePos, GLOBE_MIDDLE, CAM_DEFAULT_Z, true)
    // this.camCtrl.setControler(this.orbitcam);
    this.objRotation.startGrab(this.globeNode)
  }

  async setCocktailMode() {
    this._listMode = true
    for (let i = 0; i < content_state.articles.length; i++) {
      this.scaleHotspot(contentTypes.ARTICLE, i, 0.01)
    }

    quat.copy(this.saveRotation, this.globeNode.rotation)
    this.objRotation.stop()
    // this.camCtrl.setControler(null);
    for (let i = 0; i < this.model.cocktails.length; i++) {
      const hp = this.model.cocktails[i];
      hp.scale[0] = 0.01
      hp.scale[1] = 0.01
      hp.scale[2] = 0.01
      hp.invalidate()
    }
    content_state.setContentMode(contentTypes.COCKTAIL)
    await this.setHotspotCenter(0, false, false, true)

  }

  async setArticleMode() {
    this.model.gltfModule.renderArticles = true
    this._listMode = false
    for (let i = 0; i < this.model.cocktails.length; i++) {
      this.scaleHotspot(contentTypes.COCKTAIL, i, 0.01)
    }
    for (let i = 0; i < this.model.hotspots.length; i++) {
      this.scaleHotspot(contentTypes.ARTICLE, i, 1)
    }
    await this.moveCameraTo(this.saveGlobPos, GLOBE_MIDDLE, CAM_DEFAULT_Z, true)
    // globe_state.setGlobeMode(contentTypes.ARTICLE)

    this.objRotation.startGrab(this.globeNode)
  }

  async setHotspotCenter(id: number, skipSavePos: boolean = false, isBack: boolean = false, saveGlobPos = false) {
    if (globe_state.currentHotSpot !== -1 || this._isAllExperience) return
    let listId = id
    let nodeList: GltfNode[] = this.model.cocktails
    let type: contentTypes = contentTypes.COCKTAIL

    // Handle all experiences
    if (this._isAllExperience) {
      const list: Array<Article> = content_state.globContent[id].type === contentTypes.ARTICLE ? content_state.articles : content_state.cocktails
      listId = this.getFromRightList(content_state.globContent[id].latlng, list)

      type = content_state.globContent[id].type

      nodeList = content_state.globContent[id].type === contentTypes.ARTICLE ? this.model.hotspots : this.model.cocktails

      if (listId === -1) console.error("this id is doesnt exist in type", content_state.globContent[id].type)
    }

    this.handleCenterScaleHotspots(listId, type)

    this.zoomTo(listId, nodeList, type, CAM_ROLL_Z, 0, false, skipSavePos, saveGlobPos)
  }

  handleCenterScaleHotspots(id: number, type: contentTypes) {
    let scale = SCALE_WHILE_ZOOM
    for (let i = 0; i < this.model.cocktails.length; i++) {
      this.scaleHotspotAntenna(contentTypes.COCKTAIL, i, 1)
      scale = SCALE_WHILE_ZOOM
      if (type === contentTypes.COCKTAIL && i === id) scale = 1
      this.scaleHotspot(contentTypes.COCKTAIL, i, scale)
    }

    if (this._isAllExperience) {
      for (let i = 0; i < this.model.hotspots.length; i++) {
        if (this.model.hotspots[i].extras.isUS) continue
        this.scaleHotspotAntenna(contentTypes.ARTICLE, i, 1)
        scale = SCALE_WHILE_ZOOM
        if (type === contentTypes.ARTICLE && i === id) scale = 1
        this.scaleHotspot(contentTypes.ARTICLE, i, scale)
      }
    }
  }

  getFromRightList(latlng: Array<number>, list: Array<Article>) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].latlng[0] === latlng[0] && list[i].latlng[1] === latlng[1]) return i
    }

    return -1
  }

  async zoomTo(id: number, list: Array<GltfNode>, type: contentTypes, level: number = 2.5, offsetX: number = 20, isClick: boolean = true, skipSavePos: boolean = false, saveGlobPos: boolean = false) {
    const hp = list[id]

    this._listMode = level === CAM_ROLL_Z

    if (isClick) {
      if (this._isAllExperience) {
        this.scaleHotspotAntenna(type, id, 10)
        for (let i = 0; i < this.model.cocktails.length; i++) {
          if (i !== id) this.scaleHotspot(contentTypes.COCKTAIL, i, 0.01)
        }

        for (let i = 0; i < this.model.hotspots.length; i++) {
          if (i !== id) this.scaleHotspot(contentTypes.ARTICLE, i, 0.01)
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (i === id) {
            this.scaleHotspot(globe_state.globeMode, i, 1)
            this.scaleHotspotAntenna(globe_state.globeMode, i, 10)
          }
          else this.scaleHotspot(globe_state.globeMode, i, 0.01)
        }
      }


    }

    if (isClick) this.toggleBlur(1)
    await this.moveCameraTo(hp.position, hp, level, false, skipSavePos, saveGlobPos)
  }

  scaleHotspotAntenna(type: contentTypes, id: number, to: number) {
    const name: string = type + id
    const hp: GltfNode = this.insideGlobeNode._children.filter((mr: Node) => (mr as GltfNode).name === name)[0] as GltfNode as GltfNode

    const antenna: GltfNode = hp._children[0] as GltfNode

    if (antenna.scale[1] === to) return

    const scale = {
      s: antenna.scale[1]
    }
    return gsap.to(scale, {
      duration: 2,
      ease: Expo.easeInOut,
      s: to,
      onUpdate: () => {
        antenna.scale[1] = scale.s
        antenna.invalidate()
      }
    })
  }

  scaleHotspot(type: contentTypes, id: number, to: number = 1, delay: number = 0) {
    const name: string = type + id
    const hp: GltfNode = this.insideGlobeNode._children.filter((mr: Node) => (mr as GltfNode).name === name)[0] as GltfNode as GltfNode

    const scale = {
      s: hp.scale[0]
    }

    return gsap.to(scale, {
      duration: 2,
      ease: Expo.easeInOut,
      s: to,
      delay,
      onUpdate: () => {
        hp.scale[0] = scale.s
        hp.scale[1] = scale.s
        hp.scale[2] = scale.s
        hp.invalidate()
      }
    })
  }

  async toggleBlur(v: number) {
    if (v > 0) this.scene.post.toggleVignetteBlur(true)
    return gsap.to(this.scene.post.vignetteblur, {
      duration: 2,
      effectStrength: v,
      ease: Expo.easeInOut
    }).then(() => {
      if (v === 0) this.scene.post.toggleVignetteBlur(false)
    })
  }

  moveCameraTo(toV: vec3, lookV: vec3 | Node, depth: number = 3, isBack: boolean = false, skipSavePos: boolean = false, saveGlobPos: boolean = false) {

    vec3.copy(this.prevLookAt, TARGET)

    vec3.set(V3A3, 0, 0, 1)
    quat.invert(Q1, this.globeNode.rotation)
    quat.normalize(Q1, Q1)

    vec3.transformQuat(V3A3, V3A3, Q1)
    vec3.copy(V3A2, V3A3)
    if (saveGlobPos) {
      vec3.copy(this.saveGlobPos, V3A3)
    } else if (!isBack && !skipSavePos) {
      vec3.copy(this.savePos, V3A3)
    }
    vec3.copy(V3A3, cartesianToPolar(V3A3))

    if (!lookV["_wmatrix"]) vec3.copy(this.lookat, lookV as vec3)

    const from = {
      lat: V3A3[1],
      lng: V3A3[0],
      lerpLA: 0,
      camZ: this.scene.camera.position[2],
      camYL: 0,
      yChange: this.yChange
    }

    //We go to hotspot lng lat
    vec3.copy(V3A, cartesianToPolar(toV))
    const to = {
      lat: 0,
      lng: V3A[0] - (isBack ? 0 : 20),
    }


    if (Math.abs(to.lng - from.lng) > 180) {
      if (to.lng > 0) { // if to is positive we remove a full-circle, add it otherwise
        to.lng = to.lng - 360;
      } else {
        to.lng = to.lng + 360;
      }
    }

    const fromY = this.scene.camera.position[1]
    const fromZ = this.scene.camera.position[2]

    const duration = isBack ? 2 : 3

    const ease = Cubic.easeInOut

    let yChangeTarget = 0
    if (lookV["_wmatrix"]) {
      const n: Node = lookV as Node
      yChangeTarget = !this._listMode || !content_state.isMobile ? 0 : (n._wmatrix[13] < 0 ? 0.3 : 0.1)
    }

    if (isBack) quat.copy(Q2, this.saveRotation)
    else this.getRotQuatForPoint(Q2, to.lng, to.lat)

    quat.copy(Q3, this.globeNode.rotation)

    if (this.tweenDest) this.tweenDest.kill()

    this.tweenDest = gsap.to(from, {
      lat: to.lat,
      lng: 0,
      lerpLA: 1,
      duration,
      camZ: depth,
      camYL: 1,
      overwrite: "auto",
      yChange: yChangeTarget,
      onUpdate: () => {
        quat.slerp(this.globeNode.rotation, Q3, Q2, from.lerpLA)
        this.globeNode.invalidate()

        let toY = CAMERA_POS[1]
        let toZ = depth
        if (lookV["_wmatrix"]) {
          const n: Node = lookV as Node
          const yChange = this.yChange = from.yChange
          const y = n._wmatrix[13] - yChange
          vec3.set(this.lookat, n._wmatrix[12], y, n._wmatrix[14])
          vec3.set(V3A, n._wmatrix[12], y, n._wmatrix[14])
          vec3.normalize(V3A, V3A)
          vec3.scale(V3A, V3A, depth)
          toY = clamp(V3A[1], -0.5, 0.5)
        }


        this.scene.camera.position[1] = fromY * (1 - from.camYL) + toY * from.camYL
        this.scene.camera.position[2] = fromZ * (1 - from.camYL) + toZ * from.camYL
        vec3.lerp(TARGET, this.prevLookAt, this.lookat, from.lerpLA)
        this.scene.camera.lookAt(TARGET)
      },
      ease
    })

    return this.tweenDest
      .then(() => vec3.copy(this.prevLookAt, TARGET))
  }

  getRotQuatForPoint(q: quat, lng: number, lat: number) {
    vec3.copy(V3A, polarToCartesian(lng, lat, 1))
    vec3.normalize(V3A, V3A)
    vec3.copy(V3A2, [0, 0, 1])
    quatFromUnitVectors(Q1, V3A, V3A2)

    quat.copy(q, Q1)
  }
}