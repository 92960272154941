
/**
 * Enum for render pass
 */
//TODO: rename to Mask
enum Masks {

  OPAQUE        = 1<<0,
  BLENDED       = 1<<1,
  BLENDED2      = 1<<2,

}

export default Masks;