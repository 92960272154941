
export function createLines($words:Array<HTMLElement>) {
    let currentHeight = 0
    let inc = 0
    let $lines = [[]]
    for (let i = 0; i < $words.length; i++) {
        const h = $words[i].getBoundingClientRect().top
        if (currentHeight === 0) currentHeight = h
        if (h === currentHeight) {
            $lines[inc].push($words[i].innerHTML)
        } else {
            currentHeight = h
            inc++
            $lines[inc] = [$words[i].innerHTML]
        }
    }

    let divs = ''
    for (let i = 0; i < $lines.length; i++) {
        divs += `<div>${$lines[i].join(' ')}</div>`
    }
    return divs
}