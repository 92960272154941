import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import { US_HOTSPOT_NAME } from '@/common/webgl-vue'
import { getInURL } from '@/core/UrlParams'
import tag from '@/core/tag'

const dummyImage = [
  {
    "src": "https://pp-int.hennessy.com/sites/hennessy/files/styles/landscape_1010x540/public/2021-06/hny-nba-bondi-2880x1540.jpg?itok=GvNN60T6",
    "meta": {
      "width": 1010,
      "height": 540
    }
  },
  {
    "src": "https://pp-int.hennessy.com/sites/hennessy/files/styles/landscape_785x420/public/2021-06/hny-nba-bondi-2880x1540.jpg?itok=Hqar77ri",
    "meta": {
      "width": 785,
      "height": 420
    }
  },
  {
    "src": "https://pp-int.hennessy.com/sites/hennessy/files/styles/square_400x400/public/2021-06/hny-nba-bondi-2880x1540.jpg?itok=OYOYyAic",
    "meta": {
      "width": 400,
      "height": 400
    }
  }
]

export enum contentTypes {
  ARTICLE = "article",
  COCKTAIL = "cocktail"
}

type ImageData = {
  src: string,
  meta: {
    width: number,
    height: number
  }
}

type VideoData = {
  src: string
}

export type Article = {
  type: contentTypes.COCKTAIL | contentTypes.ARTICLE,
  hotspot_id: string,
  banner?: Array<ImageData>,
  teamLogo?: Array<ImageData>,
  image: Array<ImageData>,
  latlng: Array<number>,
  video: VideoData,
  city: string,
  team: string,
  title: string,
  description: string,
  sticky: boolean,
  cta?: {
    title: string,
    href: string
  }
}

function transformRawToArticle(a: any, i: number): Article {
  const art: Article = {
    type: a.field_associated_city === US_HOTSPOT_NAME ? contentTypes.ARTICLE : a.field_category,
    hotspot_id: a.id,
    banner: getImageUrls(a.field_cocktail_image),
    teamLogo: getImageUrls(a.field_icon_nba_team),
    image: getImageUrls(a.field_main_image),
    latlng: [a.field_latitude, a.field_longitude],
    video: getVideoUrl(a.field_video),
    city: a.field_associated_city,
    team: a.field_name_nba_team,
    title: a.title,
    description: a.field_map_description.processed,
    sticky: a.sticky,
    cta: a.field_cta_externe ? {
      title: a.field_cta_externe.title,
      href: a.field_cta_externe.uri
    } : null
  }

  return art
}

function getVideoUrl(field): VideoData {
  if (field.field_media_video_file && field.field_media_video_file.uri) {
    let video = {
      src: field.field_media_video_file.uri.url
    }
    return video
  }

  return null
}

function getImageUrls(field): ImageData[] {
  if (field.field_media_image && field.field_media_image.links) {

    const imgs = []
    for (const [key, value] of Object.entries(field.field_media_image.links)) {
      if ((value as any).meta) {
        imgs.push({
          src: (value as any).href,
          meta: {
            width: Number.parseInt((value as any).meta.width),
            height: Number.parseInt((value as any).meta.height)
          }
        })
      }

    }

    return imgs
  }

  return null
}

function generateRandomLong() {
  var num = getRandomInRange(-180, 180, 3);
  var posorneg = Math.floor(Math.random());
  if (posorneg == 0) {
    num = num * -1;
  }
  return num;
}
// LATITUDE -90 to +90
function generateRandomLat() {
  var num = getRandomInRange(-90, 90, 3);
  var posorneg = Math.floor(Math.random());
  if (posorneg == 0) {
    num = num * -1;
  }
  return num;
}

function getRandomInRange(from, to, fixed) {
  return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  // .toFixed() returns string, so ' * 1' is a trick to convert to number
}

function getListWithoutSticky(list, sticky): Article[] {
  return list.filter(a => !sticky || a.hotspot_id !== sticky.hotspot_id)
}

// function getCocktailsInOrder(list, sticky): Article[] {
//   return [
//     ...(sticky && sticky.type === contentTypes.COCKTAIL
//       ? [sticky]
//       : []
//     ),
//     ...list.filter((a) =>
//       a.type === contentTypes.COCKTAIL && (
//         !sticky || a.hotspot_id !== sticky.hotspot_id)
//     )
//   ]
// }

@Module
export class ContentState extends VuexModule {

  // ====================================================== whole content
  globContent: Array<Article> = []
  // All articles
  articles: Array<Article> = []
  //All cocktails
  cocktails: Array<Article> = []

  @Action
  setWholeContent(arr: Array<Object>) {
    const artArr: Array<Article> = arr.map((a: Object, i: number) => transformRawToArticle(a, i))
    this._SET_WHOLE_CONTENT(artArr)
  }

  @Mutation
  private _SET_WHOLE_CONTENT(wCtn: Array<Article>) {
    if (getInURL("fillFakeData") !== null) {
      while (wCtn.length < 30) {
        wCtn.push({
          type: Math.random() > 0.5 ? contentTypes.COCKTAIL : contentTypes.ARTICLE,
          hotspot_id: Math.random().toString(),
          image: [],
          latlng: [generateRandomLat(), generateRandomLong()],
          video: null,
          city: "test",
          team: "test",
          title: "test",
          description: "test",
          sticky: false
        })
      }
    }
    if (getInURL("addSticky") !== null) {
      wCtn.push({
        type: contentTypes.COCKTAIL,
        hotspot_id: Math.random().toString(),
        image: dummyImage,
        latlng: [generateRandomLat(), generateRandomLong()],
        video: null,
        city: "test",
        team: "test",
        title: "test",
        description: "test",
        sticky: true
      })
    }

    const sticky = wCtn.find(a => a.sticky === true)
    this.articles = wCtn.filter((a) => a.type === contentTypes.ARTICLE && a.city !== US_HOTSPOT_NAME)
    // this.cocktails = getCocktailsInOrder(wCtn, sticky)
    this.cocktails = []

    this.globContent = [
      ...(sticky ? [sticky] : []),
      ...getListWithoutSticky(this.articles, sticky),
      ...getListWithoutSticky(this.cocktails, sticky)
    ]
    //Remove us hotspot from all articles
    let toRemove = -1
    for (let i = 0; i < this.globContent.length; i++) {
      if (this.globContent[i].city === US_HOTSPOT_NAME) {
        toRemove = i
        break;
      }
    }
    if (toRemove !== -1) this.globContent.splice(toRemove, 1)
    console.log(this.globContent)
  }

  // ====================================================== current content
  content: Article = null

  @Action
  setContent(hotspotID: string) {
    this._SET_CURRENT_CONTENT(hotspotID)
  }

  @Mutation
  private _SET_CURRENT_CONTENT(id: string) {
    const art: Article[] = content_state.globContent.filter((gb: Article) => gb.hotspot_id === id)
    if (art.length > 0) {
      this.content = art[0]
      const event = content_state.isAllExperiences ? "mapExperienceClick" : (content_state.contentMode === contentTypes.COCKTAIL ? "mapCocktailListClick" : "mapStoryClick")
      tag(event, { experienceName: this.content.title })
    }
  }

  // ====================================================== which type of content is currently shown
  contentMode: contentTypes.COCKTAIL | contentTypes.ARTICLE = contentTypes.ARTICLE

  @Action
  setContentMode(mode: contentTypes.COCKTAIL | contentTypes.ARTICLE) {
    this._SET_CONTENT_TYPE(mode)
  }

  @Mutation
  private _SET_CONTENT_TYPE(mode: contentTypes.COCKTAIL | contentTypes.ARTICLE) {
    this.contentMode = mode
  }

  // ====================================================== all articles & cocktails mode
  isAllExperiences: boolean = false

  @Action
  setIsAllExperiences(flag: boolean) {
    this._SET_IS_ALL_EXPERIENCES(flag)
  }

  @Mutation
  private _SET_IS_ALL_EXPERIENCES(flag: boolean) {
    this.isAllExperiences = flag
  }

  // ====================================================== is the xp shown in mobile
  isMobile: boolean = false

  @Action
  setIsMobile(isMobile: boolean) {
    this._SET_IS_MOBILE(isMobile)
  }

  @Mutation
  private _SET_IS_MOBILE(isMobile: boolean) {
    this.isMobile = isMobile
  }
}

const content_state = new ContentState({ store, name: 'content_state' })

export default content_state
