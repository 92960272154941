





















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

declare global {
  interface Element {
    requestFullScreen?(): void;
    webkitRequestFullscreen?(): void;
    msRequestFullscreen?(): void;
  }
}

@Component
export default class VideoMedia extends Vue {
  @Prop({ type: String, required: true }) url: string;
  @Prop({ type: String, required: true }) poster: string;

  // Lifecycle

  // Methods
  init() {
    this.bindEvents();
  }

  kill() {
    this.unbindEvents();
  }

  bindEvents() {
    const $video: HTMLVideoElement = this.$refs.videoMedia as HTMLVideoElement;

    // console.log($video)
    $video.addEventListener("fullscreenchange", this.onFullScreenChange);
  }

  unbindEvents() {
    const $video: HTMLVideoElement = this.$refs.videoMedia as HTMLVideoElement;
    $video.removeEventListener("fullscreenchange", this.onFullScreenChange);
  }

  fullscreen() {
    const $video: any = this.$refs.videoMedia as any;
    if ($video.requestFullscreen) {
      $video.requestFullscreen();
    } else if ($video.webkitEnterFullscreen) {
      /* IOS */
      $video.webkitEnterFullscreen();
    } else if ($video.webkitRequestFullscreen) {
      /* Safari */
      $video.webkitRequestFullscreen();
    } else if ($video.msRequestFullscreen) {
      /* IE11 */
      $video.msRequestFullscreen();
    }
  }
  // Events
  onClick() {
    this.fullscreen();
  }

  onFullScreenChange() {
    const video: HTMLVideoElement = this.$refs.videoMedia as HTMLVideoElement;
    if (document.fullscreenElement) {
      video.play();
    } else {
      video.currentTime = 0;
      video.pause();
    }
  }
}
