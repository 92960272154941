import content_state from "@/store/modules/content_state";
import globe_state from "@/store/modules/globe_state";
import { vec3, quat, mat4 } from "gl-matrix";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import { clamp } from "../math";
import { ICameraController } from "./ICameraController";

var M4 = mat4.create()
var Q = quat.create()
var Q1 = quat.create()
var V3 = vec3.create()
var V3A = vec3.create()
var V3B = vec3.create()

function setMousePos(e: any, el: any, v3: vec3) {
    var cX, cY;
    if (e.touches && e.touches[0]) {
        cX = e.touches[0].clientX
        cY = e.touches[0].clientY
    } else {
        cX = e.clientX
        cY = e.clientY
    }
    v3[0] = 2 * cX / (el.width) - 1
    v3[1] = -(2 * cY / (el.height) - 1)
}

export interface IOrbitOpts {

    rotateSpeed?: number;
    wheelSpeed?: number;
    dragSpeed?: number;
    dist?: number;
    minDist?: number;
    maxDist?: number;
    center?: vec3
    _theta?: number;
    _phi?: number;
    minPhi?: number,
    maxPhi?: number,
}

export default class RotationController {

    el: HTMLElement | Element;
    rotateSpeed: number
    wheelSpeed: number
    dragSpeed: number
    dist: number
    minDist: number
    maxDist: number
    center: any
    _theta: number
    _phi: number
    mouseButton1: boolean
    mouse: vec3
    mStartDrag: vec3
    mDrag: vec3
    pan: vec3
    panInitialX: vec3
    panInitialY: vec3
    panInitialP: vec3
    _drag: vec3;
    obj: Node;

    minPhi: number
    maxPhi: number

    currTuto: number
    dragTuto: number

    autoRotate: boolean
    autoRotateAdd: number
    autoRotateDelay: number

    constructor(el: HTMLElement | Element, opts: IOrbitOpts = {}) {

        this.el = el
        this.rotateSpeed = opts.rotateSpeed ? opts.rotateSpeed : 30
        this.wheelSpeed = opts.wheelSpeed ? opts.wheelSpeed : .5
        this.dragSpeed = opts.dragSpeed ? opts.dragSpeed : 30
        this.dist = opts.dist ? opts.dist : 10
        this.minDist = opts.minDist ? opts.minDist : 1
        this.maxDist = opts.maxDist ? opts.maxDist : 100
        this.center = opts.center ? opts.center : vec3.fromValues(0, 0, 0)
        this._theta = opts._theta ? opts._theta : 0
        this._phi = opts._phi ? opts._phi : 0
        this.minPhi = opts.minPhi ? opts.minPhi : 0
        this.maxPhi = opts.maxPhi ? opts.maxPhi : Math.PI

        this.mouseButton1 = false
        this.mouse = vec3.fromValues(0, 0, 1)
        this.mStartDrag = vec3.fromValues(0, 0, 1)
        this.mDrag = vec3.fromValues(0, 0, 1)
        this._drag = vec3.fromValues(this._theta, this._phi, 1)

        this.obj = null

        this.autoRotate = true
        this.autoRotateAdd = 0
    }

    start(obj: Node): void {
        this.obj = obj
        this.dragTuto = 0
    }


    startGrab(obj: Node): void {
        this.obj = obj
        this.autoRotateDelay = setTimeout(() => {
            this.autoRotate = true
        }, 2000)

        this.el.addEventListener('mousemove', this.onMouseMove)
        this.el.addEventListener('touchmove', this.onMouseMove)
        this.el.addEventListener('touchstart', this.onMouseDown)
        this.el.addEventListener('mousedown', this.onMouseDown)
        this.el.addEventListener('mouseup', this.onMouseUp)
        this.el.addEventListener('touchend', this.onMouseUp)
        this.el.addEventListener('wheel', this.onMouseWheel)
    }

    stop(): void {
        this.obj = null
        clearTimeout(this.autoRotateDelay)
        this.autoRotate = false
        this.autoRotateAdd = 0

        this.el.removeEventListener('mousemove', this.onMouseMove)
        this.el.removeEventListener('touchmove', this.onMouseMove)
        this.el.removeEventListener('touchstart', this.onMouseDown)
        this.el.removeEventListener('mousedown', this.onMouseDown)
        this.el.removeEventListener('mouseup', this.onMouseUp)
        this.el.removeEventListener('touchend', this.onMouseUp)
        this.el.removeEventListener('wheel', this.onMouseWheel)
    }


    onMouseWheel = (e: any) => {
        var dir = e.deltaY > 0 ? 1 : -1
        dir *= this.wheelSpeed
        this.dist += dir //* 0.7
        this.dist = Math.max(this.minDist, Math.min(this.dist, this.maxDist))
    }

    onMouseDown = (e: any) => {
        clearTimeout(this.autoRotateDelay)

        this.mouseButton1 = true
        this.autoRotate = false
        this.autoRotateAdd = 0

        setMousePos(e, this.el, this.mouse)
        vec3.copy(this.mStartDrag, this.mouse)
        this.el.classList.add("grabbing")
    }

    onMouseMove = (e: any) => {
        if (!this.mouseButton1) { return }

        if (content_state.isMobile) {
            const offsetY = this.el.getBoundingClientRect().top;
            const height = this.el.getBoundingClientRect().height;

            var cY;
            if (e.touches && e.touches[0]) {
                cY = e.touches[0].clientY
            } else {
                cY = e.clientY
            }

            const posY = cY - offsetY;

            // Only allow dragging within middle section on mobile
            if (posY < height * 0.20 || posY > height * 0.80) { return }
        }

        setMousePos(e, this.el, this.mouse)
        this.mDrag[0] = (this.mouse[0] - this.mStartDrag[0])
        this.mDrag[1] = (this.mouse[1] - this.mStartDrag[1])
        vec3.copy(this.mStartDrag, this.mouse)
        this._drag[0] += this.mDrag[0] * this.dragSpeed / 4
        this._drag[1] -= this.mDrag[1] * this.dragSpeed / 4
        this._drag[1] = clamp(this._drag[1], this.minPhi, this.maxPhi)
        this.dragTuto += this.mDrag[0] * this.dragSpeed / 4
        // if (this._drag[1] <= 0.001) this._drag[1] = 0.001
        // if (this._drag[1] >= Math.PI - 0.001) this._drag[1] = Math.PI - 0.001
    }

    onMouseUp = (e: any) => {
        this.mouseButton1 = false
        this.autoRotateDelay = setTimeout(() => {
            this.autoRotate = true
        }, 2000)
        this.el.classList.remove("grabbing")
    }


    update(dt: number): void {

        if (!this.obj) return

        if (!globe_state.hasRotated)
            this.currTuto = Math.max(-1, Math.min(1, this.dragTuto / (Math.PI / 4)))

        if (this.autoRotate && this.autoRotateAdd < 0.004) this.autoRotateAdd += 0.0002
        if (this.autoRotate) this._drag[0] += this.autoRotateAdd

        var r = this.dist

        var s = dt * this.rotateSpeed
        this._theta += (this._drag[0] - this._theta) * s
        this._phi += (this._drag[1] - this._phi) * s

        quat.identity(this.obj.rotation)
        quat.setAxisAngle(Q, [0, 1, 0], this._theta)
        quat.setAxisAngle(Q1, [1, 0, 0], this._phi)
        quat.copy(this.obj.rotation, Q)
        quat.mul(this.obj.rotation, Q1, this.obj.rotation)
        this.obj.invalidate()
    }

}