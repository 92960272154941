import { quat, vec3 } from "gl-matrix";

export default function quatFromUnitVectors( q:quat, vFrom:vec3, vTo:vec3 ) {

    // assumes direction vectors vFrom and vTo are normalized

    let _x, _y, _z, _w

    let r = vec3.dot( vFrom, vTo ) + 1;

    if ( r < Number.EPSILON ) {

        // vFrom and vTo point in opposite directions

        r = 0;

        if ( Math.abs( vFrom[0] ) > Math.abs( vFrom[2] ) ) {

            _x = - vFrom[1];
            _y = vFrom[0];
            _z = 0;
            _w = r;

        } else {

            _x = 0;
            _y = - vFrom[2];
            _z = vFrom[1];
            _w = r;

        }

    } else {

        // crossVectors( vFrom, vTo ); // inlined to avoid cyclic dependency on Vector3

        _x = vFrom[1] * vTo[2] - vFrom[2] * vTo[1];
        _y = vFrom[2] * vTo[0] - vFrom[0] * vTo[2];
        _z = vFrom[0] * vTo[1] - vFrom[1] * vTo[0];
        _w = r;

    }

    quat.set(q, _x, _y, _z, _w)
    quat.normalize(q, q)

    return q;

}