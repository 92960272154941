











































import content_state, { contentTypes } from "@/store/modules/content_state";
import globe_state from "@/store/modules/globe_state";
import Vue from "vue";
import Component from "vue-class-component";
import HotspotCard from "@/components/HotspotCard/HotspotCard.vue";
import CocktailCard from "@/components/CocktailCard/CocktailCard.vue";
import { gsap } from "gsap";

import ArrowLeft from "!vue-svg-loader!@/assets/icons/arrow-left.svg";

@Component({
  components: { HotspotCard, CocktailCard, ArrowLeft },
})
export default class HotspotList extends Vue {
  // Datas
  down: boolean = false
  mouseY: number = 0
  dragged: boolean = false
  timeline: any;
  opened: boolean = false;
  isMobile: boolean = false;

  // Props

  // Getters
  get cocktailHPs() {
    return content_state.cocktails;
  }

  get isAllExperience() {
    return content_state.isAllExperiences;
  }

  get hasHotspotOpen() {
    return globe_state.currentHotSpot !== -1;
  }

  get Hotspots() {
    if (this.isAllExperience) {
      return content_state.globContent;
    } else if (content_state.contentMode === contentTypes.COCKTAIL) {
      return content_state.cocktails;
    }
  }

  // Lifecycle
  mounted() {
    this.isMobile = window.innerWidth < 1024;
  }

  // Methods
  bindEvents() {
    const $ul = this.$refs.ul as HTMLElement;
    $ul.addEventListener("pointerdown", this.onPointerDown);
    $ul.addEventListener("pointermove", this.onPointerMove);
    document.addEventListener("pointerup", this.onPointerUp);
    document.addEventListener("pointercancel", this.onPointerUp);
    document.addEventListener("pointerleave", this.onPointerUp);

    document.addEventListener("pointerdown", this.onScreenClick);
  }

  unbindEvents() {
    const $ul = this.$refs.ul as HTMLElement;
    $ul.removeEventListener("pointerdown", this.onPointerDown);
    $ul.removeEventListener("pointermove", this.onPointerMove);
    document.removeEventListener("pointerup", this.onPointerUp);
    document.removeEventListener("pointercancel", this.onPointerUp);
    document.removeEventListener("pointerleave", this.onPointerUp);

    document.removeEventListener("pointerdown", this.onScreenClick);
  }

  // Transitions
  transitionIn(opts = { delay: 0, cb: () => {} }) {
    const wasOpened = this.opened;

    const $back: SVGSVGElement = this.$refs.back as SVGSVGElement;
    const $button: HTMLElement = this.$refs.backButton as HTMLElement;
    const $backText: HTMLElement = this.$refs.backText as HTMLElement;

    this.opened = true;

    const dur = 0.5;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      onStart: () => {
        this.bindEvents();
      },
      delay: opts.delay
    });

    this.timeline
      .to($back, {
        duration: dur,
        scale: 1,
        ease: "power2.out",
      })
      .to(
        $button,
        {
          duration: dur,
          scale: 1,
          ease: "power2.out",
        },
        0.1
      )
      .to(
        $backText,
        {
          duration: dur,
          opacity: 1,
          ease: "linear",
        },
        dur * 0.6
      );

    if (!wasOpened) {
      for (let i = 0; i < (this.$refs.hotspotCard as HotspotCard[]).length; i++) {
        this.timeline.add(
          this.$refs.hotspotCard[i].transitionIn({ delay: opts.delay, dur: dur }),
          this.isMobile ? (i / 2) * dur : (i / 4) * dur
        );
      }
    }

    return this.timeline;
  }

  transitionOut(opts = { delay: 0, cb: () => {} }) {
    const $back: SVGSVGElement = this.$refs.back as SVGSVGElement;
    const $button: HTMLElement = this.$refs.backButton as HTMLElement;
    const $backText: HTMLElement = this.$refs.backText as HTMLElement;

    this.opened = false;

    globe_state.setGlobeMode(contentTypes.ARTICLE);
    globe_state.setIsAllExperiences(false);

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: () => {
        if (globe_state.currentHotSpot !== -1) return;
        content_state.setContentMode(contentTypes.ARTICLE);
        content_state.setIsAllExperiences(false);
        opts.cb();
        // console.log("completed List");
      },
      onStart: () => {
        this.unbindEvents();
      },
      delay: opts.delay,
      // duration: 1, // need to look in cards
    });

    for (let i = 0; i < (this.$refs.hotspotCard as Array<HotspotCard>).length; i++) {
      this.timeline.add(
        this.$refs.hotspotCard[i].transitionOut({ dur: 0.5 }),
        0
      )
    }

    this.timeline
      .to(
        $button,
        {
          duration: 0.5,
          scale: 0,
          ease: "power2.out",
        },
        0
      )
      .to(
        $back,
        {
          duration: 0.5,
          scale: 0,
          ease: "power2.out",
        },
        0
      )
      .to(
        $backText,
        {
          duration: 0.5,
          opacity: 0,
          ease: "linear",
        },
        0
      );
  }

  hideClose() {
    const $back: SVGSVGElement = this.$refs.back as SVGSVGElement;
    const $button: HTMLElement = this.$refs.backButton as HTMLElement;
    const $backText: HTMLElement = this.$refs.backText as HTMLElement;

    this.timeline = gsap.timeline({ delay: 0.5 })
    this.timeline
      .to(
        $button,
        {
          duration: 0.5,
          scale: 0,
          ease: "power2.out",
        },
        0
      )
      .to(
        $back,
        {
          duration: 0.5,
          scale: 0,
          ease: "power2.out",
        },
        0
      )
      .to(
        $backText,
        {
          duration: 0.5,
          opacity: 0,
          ease: "linear",
        },
        0
      );
  }

  // Events
  onScreenClick(e) {
    const $parents = e.path as Array<HTMLElement>;
    const $el = this.$refs.ul as HTMLElement;
    if (e.target !== $el && $parents && !$parents.includes($el)) {
      this.onPointerUp();
      this.closeCocktails();
    }
  }

  onPointerDown(e) {
    if (this.isMobile) return;
    if (!this.down) this.down = true;

    this.mouseY = e.clientY
  }

  onPointerMove(e) {
    if (this.isMobile) return;
    if (!this.down) return;
    if (!this.dragged) {
      this.dragged = true;
      (this.$refs.ul as HTMLElement).style.cursor = "grabbing";
    }

    this.drag(this.mouseY - e.clientY);
    this.mouseY = e.clientY;
  }

  onPointerUp() {
    if (this.isMobile) return;
    if (!this.down) return;
    this.down = false;
    this.dragged = false;
    (this.$refs.ul as HTMLElement).style.cursor = "grab";
  }

  drag(value) {
    const $wrapper: HTMLElement = this.$refs.wrapper as HTMLElement;

    $wrapper.scrollTo({
      top: $wrapper.scrollTop + value,
    });
  }

  click(modId: number, id) {
    globe_state.setCurrentHotSpot(modId)
    this.unbindEvents();
    this.hideClose()
  }

  closeCocktails() {
    this.opened = false;
    this.$emit("HotspotListClosed");
  }
}
