




















































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { gsap, Quart } from "gsap";
import { SplitText } from "@/vendors/SplitText";
import { createLines } from "@/core/Splitter";
import content_state from "@/store/modules/content_state";

@Component
export default class HotspotCard extends Vue {
  // Datas
  timeline: any;

  // Props
  @Prop({ type: Number, required: true }) idx: number;
  @Prop({ type: Object, required: true }) content: object;
  @Prop({ type: Boolean }) disabled: boolean;

  get isMobile() {
    return content_state.isMobile;
  }

  // Lifecycle
  mounted() {
    // console.log(this.content)
    const $title: HTMLElement = this.$refs.title as HTMLElement;
    const splitter = new SplitText({
      words: 1,
      chars: 0,
      spacing: "0.33em",
    });
    const $words = splitter.split($title).words as Array<HTMLElement>;

    $title.innerHTML = createLines($words);
    // console.log(this.content)
  }

  // Transitions
  transitionIn(opts = { delay: 0, cb: () => {}, dur: 0 }) {
    const $card: HTMLElement = this.$refs.card as HTMLElement;
    const $container: HTMLElement = this.$refs.container as HTMLElement;
    const $imageWrapper: HTMLElement = this.$refs.imageWrapper as HTMLElement;
    const $title: HTMLElement = this.$refs.title as HTMLElement;
    const $titleLines = $title.querySelectorAll("div");

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
      duration: opts.dur,
    });

    this.timeline
      .fromTo(
        $card,
        {
          x: "100%",
        },
        {
          duration: opts.dur,
          x: 0,
          ease: "power2.out",
        },
        0
      )
      .fromTo(
        $container,
        {
          x: "-100%",
        },
        {
          duration: opts.dur,
          x: 0,
          ease: "power2.out",
        },
        0
      )
      .fromTo(
        $imageWrapper,
        {
          scale: "1.1",
        },
        {
          duration: opts.dur / 2,
          scale: 1,
          ease: "power2.out",
        },
        opts.dur / 2
      )
      .fromTo(
        $titleLines,
        {
          opacity: 0,
          y: "1rem",
          ease: "power2.out",
        },
        {
          duration: opts.dur / 2,
          opacity: 1,
          y: "0",
          stagger: 0.05,
          ease: "power2.out",
        },
        opts.dur / 2
      );

    return this.timeline;
  }

  transitionOut(opts = { delay: 0, cb: () => {}, dur: 0 }) {
    const $card: HTMLElement = this.$refs.card as HTMLElement;
    const $container: HTMLElement = this.$refs.container as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
      duration: opts.dur,
    });

    this.timeline
      .to(
        $card,
        {
          duration: opts.dur,
          x: "100%",
        },
        0
      )
      .to(
        $container,
        {
          duration: opts.dur,
          x: "-100%",
        },
        0
      );

    return this.timeline;
  }

  // Methods

  // Events
}
