












































import Vue from "vue";
import Component from "vue-class-component";
import ContentWrapper from "@/components/ContentWrapper/ContentWrapper.vue";
import { gsap, Quart } from "gsap";
import ScrollToPlugin from "@/vendors/ScrollToPlugin";
import Hotspots from "@/components/Hotspots/Hotspots.vue";
import HotspotList from "@/components/HotspotList/HotspotList.vue";
import AllExperiencesCta from "@/components/AllExperiencesCta/AllExperiencesCta.vue";
// import ScrollToButton from "@/components/ScrollToButton/ScrollToButton.vue";
import Revive from "@/components/Revive/Revive.vue";
import Thread from "@/components/Thread/Thread.vue";
import Tuto from "@/components/Tuto/Tuto.vue";
// import Intro from "@/components/Intro/Intro.vue";
import Loader from "@/components/Loader/Loader.vue";
// import Instructions from "@/components/Instructions/Instructions.vue";
import GLView from "@/components/GLView/GLView.vue";
import content_state, {
  Article,
  contentTypes,
} from "@/store/modules/content_state";
import { getInURL } from "@/core/UrlParams";
import Delay from "@/core/Delay";
import globe_state from "@/store/modules/globe_state";
import tag from "@/core/tag";

gsap.registerPlugin(ScrollToPlugin);

const SKIPDRAG = false;
const DEFAULT_LOCALE = "en-int";

@Component({
  components: {
    ContentWrapper,
    Hotspots,
    HotspotList,
    GLView,
    AllExperiencesCta,
    // ScrollToButton,
    Revive,
    Thread,
    // Intro,
    Loader,
    Tuto,
    // Instructions,
  },
})
export default class NBAAroundTheWorld extends Vue {
  public loadProgress = 0;
  public loaded: boolean = false;

  public isPlaying: boolean = false;
  public isModuleInView: boolean = false;
  // public isInstructions: boolean = true;

  private timelineIn: any;
  private timelineOut: any;

  private _cancelPause: boolean = false;

  private _imgsToLoad: string[];

  private locale: string = DEFAULT_LOCALE;

  private updatingHotspot: number = null;

  get isMobile() {
    return content_state.isMobile;
  }

  get cocktailMode() {
    return content_state.contentMode === contentTypes.COCKTAIL;
  }

  get experiencesMode() {
    return content_state.isAllExperiences;
  }

  get isLocalHost() {
    return window.location.hostname === "localhost";
  }

  get isTuto() {
    return globe_state.isTuto;
  }

  get isPreProd() {
    const hs = window.location.hostname;
    const p = window.location.port;
    return (
      hs === "uat-mapnba.hennessy.com" ||
      hs === "mapnba.hennessy.com" ||
      p === "3000" ||
      p === "3001" ||
      p === "5000"
    );
  }

  get hostURL() {
    const hs = window.location.hostname;
    const p = window.location.port;
    return this.isPreProd ? "https://pp-int.hennessy.com" : "https://" + hs;
  }

  consumerID(locale) {
    // if (this.isPreProd) return "83edfe93-1b78-4744-a79c-89f4f62f12a0";
    if (locale === "en-us") return "9ba008ce-4743-44a8-b5eb-603f5bcc208c";
    return "954fbba9-4b97-40db-9a17-2be94d14f836";
  }

  consumerIDParam(locale) {
    // if (this.isPreProd) return "";
    return `&consumerId=${this.consumerID(locale)}`;
  }

  beforeMount() {
    const isMobile = window.matchMedia("only screen and (max-width: 1023px)");
    content_state.setIsMobile(isMobile.matches);

    const canon = document.querySelector('link[rel="canonical"]');
    const href = canon ? canon.getAttribute("href") : "";
    const url = /https?:\/\/[^/]+\/([^/]+)/gi.exec(href);
    this.locale = Array.isArray(url) && url[1] ? url[1] : DEFAULT_LOCALE;
  }

  async mounted() {
    // if (getInURL("skipIntro") === null) {
    globe_state.setIsLoading(true);
    (this.$refs.loader as Loader).init();
    // (this.$refs.intro as Intro).init();
    // } else {
    //   globe_state.setIsLoading(false);
    //   this.loaded = true;
    // }

    try {
      // use this version if error on production json
      const url = this.isLocalHost
        ? "content.json"
        : `${this.hostURL}/${
            this.locale
          }/jsonapi/node/map_content?jsonapi_include=1${this.consumerIDParam(
            this.locale.toLowerCase()
          )}`;

      // use this version of production json
      // const url = `${this.hostURL}/${this.locale}/jsonapi/node/map_content?jsonapi_include=1
      //     ${this.consumerIDParam(this.locale.toLowerCase())}`;

      const json = await fetch(url);
      await this.loadJSON(json);
    } catch (err) {
      // const url = `${this.hostURL}/${DEFAULT_LOCALE}/jsonapi/node/map_content?jsonapi_include=1
      //   ${this.consumerIDParam(this.locale.toLowerCase())}`;

      console.error("FALLBACK !!!!", err);
      // const json = await fetch(url);
      // await this.loadJSON(json);
    }
  }

  async loadJSON(json) {
    const jsonResp = await json.json();
    content_state.setWholeContent(jsonResp.data);
    this.contentLoaded();

    content_state.$watch(
      (s) => s.contentMode,
      (id) => {
        if (this.cocktailMode) this.HotspotListOpened();
      }
    );

    // globe_state.$watch(
    //   (s) => s.isGlobeIntro,
    //   () => {
    //     if (!globe_state.isGlobeIntro) this.transitionIn();
    //   }
    // );

    // globe_state.$watch(
    //   (s) => s.isTuto,
    //   () => {
    //     if (globe_state.isTuto)
    //       (this.$refs.instructions as Instructions).init();
    //     else this.onInstructionsEnded();
    //   }
    // );

    globe_state.$watch(
      (s) => s.isLoading,
      (v) => {
        if (!v) {
          this.loaded = true;
          this.onLoadingEnded();
        }
      }
    );

    globe_state.$watch(
      (s) => s.introDone,
      (v) => {
        this.onIntroEnded();
      }
    );

    globe_state.$watch(
      (s) => s.isTuto,
      (v) => {
        this.onTutoUpdate();
      }
    );

    // if (!SKIPDRAG && getInURL("skipIntro") === null) {
    //   await Delay(3000);
    //   this.isInstructions = false;
    // }
  }

  contentLoaded() {
    this.prepareImagesToLoad();

    this.load();

    window.addEventListener("resize", this.resize);
    // window.addEventListener("scroll", this.checkScroll);
    this.resize();

    this.playExperience();
    // uncomment if we lock scroll on mobile
    // if (this.isMobile) this.lockScroll();
    // else this.unlockScroll();
    // this.unlockScroll();
  }

  prepareImagesToLoad() {
    this._imgsToLoad = [];
    content_state.globContent.forEach((a: Article) => {
      if (a.banner && a.banner.length) {
        for (let i = 0; i < a.banner.length; i++) {
          this._imgsToLoad.push(a.banner[i].src);
        }
      }
      if (this.isMobile) {
        this._imgsToLoad.push(a.image[1].src);
      } else {
        this._imgsToLoad.push(a.image[0].src);
      }

      if (a.teamLogo && a.teamLogo.length) {
        for (let i = 0; i < a.teamLogo.length; i++) {
          this._imgsToLoad.push(a.teamLogo[i].src);
        }
      }
    });
  }

  playExperience() {
    if (!globe_state.introStart) this.startIntro();

    (this.$refs.viewer as any).play();
    this.isPlaying = true;
    // if (this.isInstructions === false) this.transitionIn();
  }

  pauseExperience() {
    (this.$refs.viewer as any).pause();
    this.isPlaying = false;
  }

  lockScroll() {
    // document.documentElement.style.height = "calc(100 * var(--vh))";
    // document.documentElement.style.overflow = "hidden";
    // document.body.style.height = "calc(100 * var(--vh))";
    // document.body.style.overflow = "hidden";
    // const module = this.$refs.module as HTMLElement;
    // module.style.position = "fixed";
    // module.style.top = "0px";
    // module.style.left = "0px";
  }

  unlockScroll() {
    // document.documentElement.style.height = "";
    // document.documentElement.style.overflow = "";
    // document.body.style.height = "";
    // document.body.style.overflow = "";
    // const module = this.$refs.module as HTMLElement;
    // module.style.position = "relative";
    // module.style.top = "auto";
    // module.style.left = "auto";
    // window.scrollTo({ top: module.offsetTop });
  }

  revive() {
    const moduleY = (this.$refs.module as HTMLElement).offsetTop;

    gsap.to(window, {
      duration: 0.75,
      scrollTo: moduleY,
      ease: Quart.easeInOut,
    });
  }

  checkInView() {
    const moduleY = (this.$refs.module as HTMLElement).getBoundingClientRect()
      .y;
    const margin = window.innerHeight * 0.1;

    return moduleY >= -margin && moduleY <= margin;
  }

  checkScroll() {
    if (globe_state.isLoading === true) return;

    const prevInView = this.isModuleInView;
    this.isModuleInView = this.checkInView();

    if (this.isModuleInView) {
      if (!prevInView) {
        this.transitionIn({
          delay: 0,
          cb: () => {},
          dur: 1,
        });

        if (!this.isPlaying) {
          this.playExperience();
          // uncomment if we lock scroll on mobile
          // if (this.isMobile) this.lockScroll();
        }
      }
    } else if (this.isPlaying && prevInView === true) {
      // tag("mapExploreContent");

      this.transitionOut({
        delay: 0,
        cb: () => {
          if (this.isModuleInView === false) {
            this.pauseExperience();
          }
        },
        dur: 1,
      });

      // uncomment if we lock scroll on mobile
      // if (this.isMobile) this.unlockScroll();
    }
  }

  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
    // window.removeEventListener("scroll", this.checkScroll);
  }

  resize() {
    (document.querySelector(":root") as HTMLElement).style.setProperty(
      "--vh",
      window.innerHeight / 100 + "px"
    );
    // (this.$refs.intro as Intro).onResize();
    if (this.$refs.allExperiencesCta)
      (this.$refs.allExperiencesCta as AllExperiencesCta).onResize();
    if (this.$refs.contentWrapper)
      (this.$refs.contentWrapper as ContentWrapper).onResize();
  }

  onPreRender() {
    (this.$refs.hotspots as Hotspots).preRender();
    if (!this.loaded) {
      (this.$refs.loader as Loader).preRender();
    }
  }

  onLoadingEnded() {
    const inView = this.checkInView();

    if (!inView) {
      // this.pauseExperience();
      this.transitionOut({
        delay: 0,
        cb: () => {},
        dur: 0,
      });

      return;
    }

    this.startIntro();
    this.checkScroll();
  }

  startIntro() {
    globe_state.setIntroStart();
    // if (this.isMobile) {
    // (this.$refs.scrollToButton as ScrollToButton).play();
    // }
  }

  async onIntroEnded() {
    globe_state.setIsTuto(true);
  }

  onTutoUpdate() {
    if (!globe_state.isTuto) {
      this.transitionIn({
        delay: 1,
        cb: () => {},
        dur: 1,
      });
    }
  }

  // onCanPlay() {
  //   if (!this.isMobile) return;

  //   if (this.timelineOut) {
  //     this.timelineOut.kill();
  //     this.timelineOut = null
  //   }

  //   this.timelineIn = gsap.timeline();
  //   this.timelineIn.add(
  //     (this.$refs.scrollToButton as ScrollToButton).transitionIn({
  //       delay: 0.6,
  //       cb: () => {
  //         this.timelineIn = null;
  //       },
  //       move: true,
  //     }),
  //     0
  //   );
  // }

  // onInstructionsEnded() {
  //   (this.$refs.instructions as Instructions).finish(() => {
  //     this.isInstructions = false;
  //     Vue.nextTick().then(() => {
  //       this.transitionIn({ delay: 0.5, cb: () => {}, dur: 0 });
  //       (this.$refs.scrollToButton as ScrollToButton).play();
  //     });
  //   });
  // }

  transitionIn(opts = { delay: 0, cb: () => {}, dur: 0 }) {
    if (this.timelineIn) return;
    if (this.timelineOut) {
      this.timelineOut.kill();
      this.timelineOut = null;
    }

    this.timelineIn = gsap.timeline({
      onComplete: () => {
        this.timelineIn = null;
        opts.cb();
      },
      delay: opts.delay,
      duration: opts.dur,
    });

    // if (this.$refs.scrollToButton && globe_state.introDone === true && globe_state.isTuto === false) {
    //   this.timelineIn.add(
    //     (this.$refs.scrollToButton as ScrollToButton).transitionIn(),
    //     0
    //   );
    // }
    if (
      this.$refs.thread &&
      globe_state.introDone === true &&
      globe_state.isTuto === false
    ) {
      this.timelineIn.add(
        (this.$refs.thread as Thread).changeTitle("main", opts.delay * 1000),
        0
      );
    }
    if (
      this.$refs.allExperiencesCta &&
      globe_state.introDone === true &&
      globe_state.isTuto === false
    ) {
      this.timelineIn.add(
        (this.$refs.allExperiencesCta as AllExperiencesCta).transitionIn(),
        0
      );
    }
  }

  transitionOut(opts = { delay: 0, cb: () => {}, dur: 0 }) {
    if (this.timelineOut) return;
    if (this.timelineIn) {
      this.timelineIn.kill();
      this.timelineIn = null;
    }

    this.timelineOut = gsap.timeline({
      onComplete: () => {
        this.timelineOut = null;
        opts.cb();
      },
      delay: opts.delay,
      duration: opts.dur,
    });

    // if (this.$refs.scrollToButton) {
    //   this.timelineOut.add(
    //     (this.$refs.scrollToButton as ScrollToButton).transitionOut(),
    //     0
    //   );
    // }
    if (this.$refs.thread) {
      this.timelineOut.add((this.$refs.thread as Thread).changeTitle(""), 0);
    }
    if (this.$refs.allExperiencesCta) {
      this.timelineOut.add(
        (this.$refs.allExperiencesCta as AllExperiencesCta).transitionOut(),
        0
      );
    }
  }

  async showedContent() {
    // this.lockScroll();

    if (this.$refs.thread) {
      (this.$refs.thread as Thread).changeTitle("");
    }
    this._cancelPause = false;
    await Delay(3000);
    if (!this._cancelPause) {
      (this.$refs.viewer as any).pause();
    }
  }

  async updateContent() {
    if (globe_state.isAllExperiences) return;

    const current = globe_state.currentHotSpot;
    this.updatingHotspot = current;
    (this.$refs.viewer as any).play();
    await Delay(3000);
    if (this.updatingHotspot === current) {
      this.updatingHotspot = null;
      if (!this._cancelPause) (this.$refs.viewer as any).pause();
    }
  }

  closeContent() {
    this._cancelPause = true;
    (this.$refs.viewer as any).play();
    const hotspotList = this.$refs.hotspotList as HotspotList;
    if (hotspotList && hotspotList.opened) {
      // hotspotList.transitionIn();
      this.HotspotListOpened();
      // if (content_state.contentMode === contentTypes.COCKTAIL) (this.$refs.thread as Thread).changeTitle('cocktail')
      // else (this.$refs.thread as Thread).changeTitle('all')
    } else {
      // (this.$refs.thread as Thread).changeTitle('main')
      const cb = () => {
        // this.unlockScroll();
      };
      this.transitionIn({ cb, delay: 0, dur: 1 });
    }
  }

  HotspotListOpened() {
    // uncomment if we lock scroll on mobile
    // if (!this.isMobile) this.lockScroll();
    // this.lockScroll();

    Vue.nextTick().then(() => {
      if (this.timelineOut) return;
      if (this.timelineIn) {
        this.timelineIn.kill();
        this.timelineIn = null;
      }

      this.timelineOut = gsap.timeline({
        onComplete: () => {
          this.timelineOut = null;
        },
      });

      this.timelineOut.add(
        (this.$refs.hotspotList as HotspotList).transitionIn({
          delay: 0.5,
          cb: () => {},
        }),
        0
      );
      this.timelineOut.add(
        (this.$refs.allExperiencesCta as AllExperiencesCta).transitionOut(),
        0
      );
      // this.timelineOut.add(
      //   (this.$refs.scrollToButton as ScrollToButton).transitionOut(),
      //   0
      // );

      if (this.$refs.thread) {
        if ((this.$refs.hotspotList as HotspotList).isAllExperience)
          (this.$refs.thread as Thread).changeTitle("all");
        else (this.$refs.thread as Thread).changeTitle("cocktail");
      }
    });
  }

  HotspotListClosed() {
    // uncomment if we lock scroll on mobile
    // if (!this.isMobile) this.unlockScroll();
    // this.unlockScroll();

    if (this.timelineIn) return;
    if (this.timelineOut) {
      this.timelineOut.kill();
      this.timelineOut = null;
    }

    this.timelineIn = gsap.timeline({
      onComplete: () => {
        this.timelineIn = null;
      },
    });

    this.timelineIn.add(
      (this.$refs.hotspotList as HotspotList).transitionOut(),
      0
    );
    this.timelineIn.add(
      (this.$refs.allExperiencesCta as AllExperiencesCta).transitionIn(),
      0.5
    );

    this.timelineIn.add((this.$refs.thread as Thread).changeTitle("main"), 0);
    // this.timelineIn.add(
    //   (this.$refs.scrollToButton as ScrollToButton).transitionIn(),
    //   "-=1"
    // );
    // (this.$refs.allExperiencesCta as AllExperiencesCta).transitionIn();
    // (this.$refs.hotspotList as HotspotList).transitionOut(() => {});
  }

  onHotspotClicked() {
    this.transitionOut();
  }

  // scrollDown() {
  //   tag("mapExploreContent");
  //   this.transitionOut({
  //     delay: 0,
  //     cb: () => {
  //       gsap.to(window, {
  //         duration: 0.75,
  //         scrollTo: window.innerHeight,
  //         ease: Quart.easeInOut,
  //         onStart: () => {
  //           this.pauseExperience();
  //           this.unlockScroll();
  //         },
  //       });
  //     },
  //     dur: 1,
  //   });
  // }

  destroyed() {}

  async loadImages() {
    return Promise.all(
      this._imgsToLoad.map((src, i) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            globe_state.setPreload((1 / this._imgsToLoad.length) * 50);
            resolve(0);
          };
          img.src = src;
        });
      })
    );
  }

  async load() {
    globe_state.setPreload(10);
    (await Promise.all([
      (this.$refs.viewer as any).load(),
      this.loadImages(),
    ])) as any;

    (this.$refs.viewer as any).play();

    globe_state.setPreload(40);

    // if (getInURL("skipIntro") !== null) {
    //   this.onIntroEnded();
    // }
  }
}
