import Scene from "@/webgl/Scene";
import { vec4 } from "gl-matrix";
import Rect from "nanogl-primitives-2d/rect";
import Program from "nanogl/program";


export default class Rect2D {

  quad: Rect

  // scale/offset
  transform = vec4.create()

  constructor( private scene: Scene ){
    this.quad = new Rect( scene.gl )
  }

  setRect( prg:Program, x: number, y: number, w: number, h: number, aspect?:number ){
    const ratio = aspect??this.scene.aspect
    this.transform[0] = w
    this.transform[1] = h*ratio
    this.transform[2] = x
    this.transform[3] = y*ratio

    prg.uRectRansform( this.transform )
  }

  setCover( prg:Program ){
    const ratio = this.scene.aspect
    if( ratio > 1.0 ){
      this.transform[0] = 1
      this.transform[1] = ratio
    } else {
      this.transform[0] = 1/ratio
      this.transform[1] = 1
    }

    this.transform[2] = 0
    this.transform[3] = 0

    prg.uRectRansform( this.transform )
  }

  setRectAndRender( prg:Program, x: number, y: number, w: number, h: number, aspect?:number ){
    this.setRect( prg, x, y, w, h, aspect )
    this.quad.attribPointer( prg )
    this.quad.render()
  }

  setCoverAndRender( prg:Program ){
    this.setCover( prg )
    this.quad.attribPointer( prg )
    this.quad.render()
  }

}