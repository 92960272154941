











import gsap from "gsap/all";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import globe_state from "@/store/modules/globe_state";
import content_state, { contentTypes } from "@/store/modules/content_state";
@Component
export default class Hotspot extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  id!: number;
  @Prop({
    type: Boolean,
  })
  isPlaying: boolean;

  visibleFace: boolean = false;

  hpSize: number = 12.5;

  get visibility(): string {
    return this.visibleFace &&
      globe_state.currentHotSpot === -1 &&
      globe_state.globeMode === contentTypes.ARTICLE
      ? "visible"
      : "hidden";
  }

  get disabled() {
    return this.visibility === "hidden" || globe_state.currentHotSpot !== -1
      || content_state.isAllExperiences || !this.$props.isPlaying;
  }

  preRender(hp: Array<number>) {
    this.visibleFace = hp[2] > 0;
    if (!this.visibleFace) return;
    gsap.set(this.$el, { x: hp[0] - this.hpSize, y: hp[1] - this.hpSize });
  }

  mounted() {
    if (content_state.isMobile) this.hpSize = 10;
  }

  over() {
    globe_state.setRollHotSpot(this.id);
  }

  out() {
    globe_state.setRollHotSpot(-1);
  }

  clickHotspot() {
    globe_state.setCurrentHotSpot(this.id);

    this.$emit("onHotspotClicked");
  }
}
