import { render, staticRenderFns } from "./Hotspot.vue?vue&type=template&id=10d623b9&scoped=true&"
import script from "./Hotspot.vue?vue&type=script&lang=ts&"
export * from "./Hotspot.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Hotspot.vue?vue&type=style&index=0&id=10d623b9&lang=stylus&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "10d623b9",
  null
  ,true
)

export default component.exports