
import WebGltfIO from "@/webgl/lib/nanogl-gltf/lib/io/web";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Scene from "@/webgl/Scene";
import Bounds from "nanogl-pbr/Bounds";
import LightSetup from "nanogl-pbr/lighting/LightSetup";
import GltfNode from "@/webgl/lib/nanogl-gltf/lib/elements/Node"
import Camera from "nanogl-camera";
import GLConfig from "nanogl-state/config";
import { Passes } from "@/webgl/glsl/Passes";
import Masks from "@/webgl/gl/Masks";
import IIblMaterialPass from "../glsl/IblMaterialPass";
import MeshRenderer from "../lib/nanogl-gltf/lib/renderer/MeshRenderer";
import Program from "nanogl/program";
import IRenderable from "../lib/nanogl-gltf/lib/renderer/IRenderable";
import { vec3 } from "gl-matrix";
import DirectionalLight from "nanogl-pbr/lighting/DirectionalLight";
import content_state, { contentTypes } from "@/store/modules/content_state";
import Node from "nanogl-node";

const V3A = vec3.create()

function getBoundsCenter(out:vec3, b:Bounds){
  out[0] = .5 * (b.min[0] + b.max[0])
  out[1] = .5 * (b.min[1] + b.max[1])
  out[2] = .5 * (b.min[2] + b.max[2])
}

export default class GltfModule {

  gltf: Gltf
  bounds: Bounds;
  lightSetup: LightSetup;

  renderCocktails:boolean
  renderArticles:boolean

  zsorting = false

  private _selectedCamera = 0;

  constructor(protected scene: Scene, protected path: string) {
    this.lightSetup = new LightSetup()
  }

  preRender(): void {
    this.gltf.root.updateWorldMatrix()
    this.getCurrentCamera().updateViewProjectionMatrix(this.scene.glview.width, this.scene.glview.height);
  }

  rttRender(): void {
    this.lightSetup.prepare(this.scene.gl);
    this.renderShadowMaps()
  }

  render(): void {
    const camera = this.getCurrentCamera()
    this.renderGltfScene(camera, Masks.OPAQUE)
    this.renderGltfScene(camera, Masks.BLENDED)
    this.renderGltfScene(camera, Masks.BLENDED2)
  }

  async load(): Promise<void> {
    this.gltf = await WebGltfIO.loadGltf(this.path, {});
    console.log('gltf loaded, waiting allocation')
    await this.gltf.allocateGl(this.scene.gl);

    this.computeBounds()
    this.setupMaterials()

    this.onLoaded()
  }

  onLoaded(): void{
    0
  }

  getCurrentCamera(): Camera {
    return this.scene.camera
    if (this._selectedCamera > -1) {
      return this.gltf.cameraInstances[this._selectedCamera]
    }
  }

  computeBounds() {

    this.gltf.root.updateWorldMatrix();
    this.bounds = new Bounds();
    const b: Bounds = new Bounds();
    Bounds.transform(this.bounds, this.gltf.renderables[0].bounds, this.gltf.renderables[0].node._wmatrix)

    for (const renderable of this.gltf.renderables) {
      Bounds.transform(b, renderable.bounds, renderable.node._wmatrix)
      Bounds.union(this.bounds, this.bounds, b);
    }
  }

  setupMaterials() {
    // this.lightSetup.add( this.iblMngr.ibl )
    this.lightSetup.bounds = this.bounds;

    const lights = this.gltf.extras.lights.list;
    if (lights) {
      for (const light of lights) {
        (light as DirectionalLight).castShadows(false);
        // (light as PunctualLight )._shadowmapSize = 2048;
        this.lightSetup.add(light);
      }
    }

    // if (this.iblMngr)
    //   this.lightSetup.add(this.iblMngr.ibl)
    

    for (const material of this.gltf.materials) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pass: IIblMaterialPass = (material as any).materialPass
      if (pass) {
        pass.setLightSetup(this.lightSetup)
        // this.iblMngr?.setupMat(pass);
      }
    }
  }

  sortRenderables( camera : Camera, renderables: IRenderable[], backToFront = false ) : IRenderable[]{
    const sortedList: {r:IRenderable, z:number}[] = []    
    for (let i = 0; i < renderables.length; i++) {
      const r = renderables[i] as MeshRenderer;
      r.bounds
      getBoundsCenter( V3A , r.bounds )
      vec3.transformMat4( V3A, V3A, r.node._wmatrix )
      vec3.transformMat4( V3A, V3A, camera._viewProj )
      const z = V3A[2]
      // const z = vec3.distance(r.node._wposition as vec3, camera._wposition as vec3 )
      sortedList.push({r, z})
    }
    const mul = backToFront ? -1 :1
    
    return sortedList.sort( (a, b)=>mul*(a.z-b.z) ).map(a=>a.r)
  }

  renderGltfScene(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, cfg?: GLConfig) {
    let rlist :IRenderable[]
    if( this.zsorting ){
      rlist = this.sortRenderables( camera, this.gltf.renderables, mask === Masks.BLENDED )
    } else {
      rlist = this.gltf.renderables

    }
    for (const renderable of rlist) {
      const isCocktail:boolean = (renderable.node._parent as GltfNode).name && (renderable.node._parent as GltfNode).name.indexOf(contentTypes.COCKTAIL) > -1
      const isArticle:boolean = (renderable.node._parent as GltfNode).name && (renderable.node._parent as GltfNode).name.indexOf(contentTypes.ARTICLE) > -1
      if( (!this.renderCocktails && isCocktail) ||
          (!this.renderArticles && isArticle)) {
        continue
      }
      if((isCocktail || isArticle) && this.cullHotspots(renderable.node)) continue
      renderable.render(this.scene, camera, mask, passId, cfg)
    }
  }

  cullHotspots(n:Node) {
    vec3.set(V3A, n._wmatrix[12], n._wmatrix[13], n._wmatrix[14])
    return vec3.dot(V3A, this.scene.camera.position) < -0.75
  }

  renderProgram(camera: Camera, prg: Program) {
    for (const renderable of this.gltf.renderables) {
      const meshRenderer = renderable as MeshRenderer

      for (let i = 0; i < meshRenderer.mesh.primitives.length; i++) {
      
        const primitive = meshRenderer.mesh.primitives[i];
        if (prg.uMVP) {
            prg.uMVP(camera.getMVP(renderable.node._wmatrix))
        }
        if (prg.uWorldMatrix)
            prg.uWorldMatrix(renderable.node._wmatrix);
        if (prg.uVP)
            prg.uVP(camera._viewProj);

      
        primitive.bindVao(prg)
        primitive.render()
        primitive.unbindVao()

      }
    }

  }


  renderShadowMaps() {
    const lights = this.gltf.extras.lights.list;
    if (!lights) return;

    const gl = this.scene.gl;
    const glstate = this.scene.glstate;

    const isRgb = this.lightSetup.depthFormat.value() === 'D_RGB';
    const config = new GLConfig()
      .enableCullface(true)
      .enableDepthTest(true)
      .depthMask(true)
      .colorMask(isRgb, isRgb, isRgb, isRgb);


    for (const l of lights) {
      if (l._castShadows) {
        l.bindShadowmap()
        // fbodebug.debug( l._fbo );
        gl.clearColor(1, 1, 1, 1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        glstate.push(config);

        // render
        this.renderGltfScene(l._camera, Masks.OPAQUE, Passes.DEPTH, config)


        glstate.pop();

      }
    }
    glstate.apply();
  }

}