














































































































import Delay from "@/core/Delay";
import content_state from "@/store/modules/content_state";
import globe_state from "@/store/modules/globe_state";
import Vue from "vue";
import { SplitText } from "@/vendors/SplitText";
import Component from "vue-class-component";
import { gsap } from "gsap";
import { createLines } from "@/core/Splitter";

import Close from "!vue-svg-loader!@/assets/icons/close.svg";
import ArrowLeft from "!vue-svg-loader!@/assets/icons/arrow-left.svg";
import ArrowRight from "!vue-svg-loader!@/assets/icons/arrow-right.svg";

import VideoMedia from "@/components/VideoMedia/VideoMedia.vue";
import UnderlineButton from "@/components/UnderlineButton/UnderlineButton.vue";

@Component({
  components: {
    VideoMedia, Close, UnderlineButton,
    ArrowLeft, ArrowRight
   },
})
export default class ContentWrapper extends Vue {
  // Datas
  timeline: any;
  showContent: boolean = false;
  content: any = {};
  splitter: any;
  dataChanging: boolean = false
  isOpen = false

  get isMobile() {
    return content_state.isMobile;
  }

  get isAllExperiences() {
    return globe_state.isAllExperiences;
  }

  get hasHotspot() {
    return globe_state.currentHotSpot !== -1;
  }

  // Props
  // @Prop({type: String, required: true}) url: string

  // Lifecycle
  mounted() {
    const $title: HTMLElement = this.$refs.title as HTMLElement;
    // this.splitText = new SplitType($title, { types: 'lines' })
    this.splitter = new SplitText({
      words: 1,
      chars: 0,
      spacing: "0.33em",
    });

    globe_state.$watch(
      (s) => s.currentCtn,
      async (id: number) => {
        this.showContent = id !== -1;
        if (this.showContent) {
          if (this.dataChanging) this.$emit("update-content");
          else this.$emit("show-content");
          this.content = content_state.content;
          if (this.isMobile || this.dataChanging) await Vue.nextTick();
          else await Delay(1000);
          this.transitionIn();
          await Vue.nextTick();
          (this.$refs.article as HTMLElement).scrollTop = 0;
        } else this.$emit("close-content");
      }
    );
  }

  updated() {
    if (this.showContent) this.init();
  }

  // Transitions
  async transitionIn() {
    this.isOpen = true
    await Vue.nextTick()

    const $el: HTMLElement = this.$el as HTMLElement;
    const $wrapper: HTMLElement = this.$refs.visibilityWrapper as HTMLElement;
    const $nav: HTMLElement = this.$refs.nav as HTMLElement;
    const $assets: Array<HTMLElement> =
      this.content.video !== null
        ? [(this.$refs.video as VideoMedia).$refs.videoMedia as HTMLElement]
        : [
            content_state.isMobile
              ? (this.$refs.imageMobile as HTMLElement)
              : (this.$refs.imageDesktop as HTMLElement),
          ];
    const $title: HTMLElement = this.$refs.title as HTMLElement;
    // const $link:HTMLElement = this.$refs.link as HTMLElement
    const $side: HTMLElement = this.$refs.side as HTMLElement;

    // $title.innerHTML = this.content.title

    // this.splitText = new SplitType($title, { types: 'lines' })

    // this.splitText.originals = [this.content.title]
    //       this.splitText.lines = null
    //   this.splitText.words = null
    //   this.splitText.chars = null
    //   this.splitText.elements =
    // this.splitText.revert()
    // this.splitText.split({ types: 'lines' })
    // const $lines = this.splitText.split()
    // console.log(this.splitText)

    const $words = this.splitter.split($title).words;

    // let currentHeight = 0
    // let inc = 0
    // let $lines = [[]]
    // for (let i = 0; i < $words.length; i++) {
    //   const h = $words[i].getBoundingClientRect().top
    //   if (currentHeight === 0) currentHeight = h
    //   if (h === currentHeight) {
    //     $lines[inc].push($words[i].innerHTML)
    //   } else {
    //     currentHeight = h
    //     inc++
    //     $lines[inc] = [$words[i].innerHTML]
    //   }
    // }

    // let divs = ''
    // for (let i = 0; i < $lines.length; i++) {
    //   divs += `<div>${$lines[i].join(' ')}</div>`
    // }

    // $title.innerHTML = divs
    $title.innerHTML = createLines($words);
    const $titleLines = $title.querySelectorAll("div");

    // const $article:HTMLElement = this.$refs.article as HTMLElement
    const $button: HTMLElement = this.$refs.button as HTMLElement;
    const $closeText: HTMLElement = this.$refs.closeText as HTMLElement;

    const dur = 0.7;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: () => {},
      onStart: () => {
        this.bindEvents();
      },
    });
    // this.timeline.timeScale(0.2)

    $wrapper.style.display = "";
    $nav.style.display = "";

    this.timeline
      .to(
        $wrapper,
        {
          duration: 1 * dur,
          width: this.isMobile ? "100vw" : this.isAllExperiences ? "calc(65vw + 2.5rem)" : "32.5rem",
          ease: "power2.out",
        },
        0
      )
      .to(
        $nav,
        {
          duration: 1 * dur,
          width: this.isMobile ? "100vw" : this.isAllExperiences ? "calc(65vw + 2.5rem)" : "32.5rem",
          ease: "power2.out",
        },
        0
      )
      // .fromTo($button, {
      //   x: '-75%'
      // }, {
      //   duration: 1,
      //   x: '-50%'
      // }, 0)
      .fromTo(
        $assets,
        {
          scale: 1.5,
        },
        {
          duration: 1 * dur,
          scale: 1,
          ease: "power2.out",
        },
        0
      );

    const delay1 = this.dataChanging ? 0.3 : dur / 2
    const delay2 = this.dataChanging ? 0.3 : (3 * dur) / 4

    if (this.$refs.video) {
      const $play: HTMLElement = (
        this.$refs.video as VideoMedia
      ).$el.querySelector("button");
      const $playSvg: SVGSVGElement = $play.querySelector("svg");

      this.timeline
        .fromTo(
          $play,
          {
            rotate: "75deg",
            borderRadius: "50%",
            scale: 0,
          },
          {
            duration: dur / 2,
            borderRadius: "0%",
            rotate: "45deg",
            scale: 1,
            ease: "power2.out",
          },
          delay1
        )
        .fromTo(
          $playSvg,
          {
            rotate: "-75deg",
            scale: 0,
          },
          {
            duration: dur / 2,
            rotate: "-45deg",
            scale: 1,
            ease: "power2.out",
          },
          delay1
        );
    }

    const $link: UnderlineButton = this.$refs.link as UnderlineButton;
    if ($link) {
      this.timeline.add($link.transitionIn(), 0);
      // const $underline:HTMLElement = $link.querySelector('span') as HTMLElement
      // this.timeline
      //   .fromTo($link, {
      //     opacity: 0,
      //     y: '1rem'
      //   }, {
      //     duration: dur / 2,
      //     opacity: 1,
      //     y: '0',
      //     ease: "power2.out"
      //   }, dur / 2)
      //   .fromTo($underline, {
      //     transformOrigin: 'left',
      //     scaleX: 0
      //   },
      //    {
      //     duration: dur / 2,
      //     scaleX: 1,
      //     ease: "power2.out"
      //   }, dur /2 )
    }

    this.timeline
      .fromTo(
        $side,
        {
          opacity: 0,
          y: "1rem",
        },
        {
          duration: dur / 2,
          opacity: 1,
          y: "0",
          ease: "power2.out",
        },
        delay1
      )
      .to(
        $titleLines,
        {
          duration: dur / 2,
          opacity: 1,
          y: "0",
          stagger: 0.05,
          ease: "power2.out",
        },
        delay2
      )

      if (this.dataChanging) {
        this.dataChanging = false
        return
      }

      this.timeline.fromTo(
        $button,
        {
          scale: "0",
        },
        {
          duration: dur / 2,
          scale: "1",
          ease: "power2.out",
        },
        delay2
      )

      if ($closeText) {
        this.timeline.fromTo(
          $closeText,
          {
            opacity: "0",
          },
          {
            duration: dur / 2,
            opacity: "1",
            ease: "linear",
          },
          delay2 + dur / 2 * 0.6
        );
      }
    // console.log(this.content)
  }

  transitionOut(cb = () => {}) {
    const $el: HTMLElement = this.$el as HTMLElement;
    const $wrapper: HTMLElement = this.$refs.visibilityWrapper as HTMLElement;
    const $nav: HTMLElement = this.$refs.nav as HTMLElement;
    const $article: HTMLElement = this.$refs.article as HTMLElement;
    const $button: HTMLElement = this.$refs.button as HTMLElement;
    const $closeText: HTMLElement = this.$refs.closeText as HTMLElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: () => {
        $wrapper.style.display = "none";
        $nav.style.display = "none";
        this.isOpen = false
        cb();
      },
      onStart: () => {
        this.unbindEvents();
      },
    });

    const dur = 0.5;

    this.timeline
      .to(
        $wrapper,
        {
          duration: dur,
          width: 0,
          ease: "power2.out",
        },
        0.2
      )
      .to(
        $nav,
        {
          duration: dur,
          width: 0,
          ease: "power2.out",
        },
        0.2
      )
      .to(
        $button,
        {
          duration: dur / 2,
          scale: "0",
        },
        0
      )

      if ($closeText) {
        this.timeline.to(
          $closeText,
          {
            duration: dur / 2,
            opacity: "0",
          },
          0
        );
      }

    // .to($button, {
    //   duration: 1,
    //   x: '-75%'
    // }, 0)
    // .to($button, {
    //   duration: 1,
    //   x:'100%'
    // }, 0)
  }

  changeData(cb = () => {}) {
    this.dataChanging = true;
    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: () => {
        cb()
        show()
      },
      onStart: () => {
        this.bindEvents();
      },
    });

    const $title: HTMLElement = this.$refs.title as HTMLElement;
    const $side: HTMLElement = this.$refs.side as HTMLElement;
    const $content: HTMLElement = this.$refs.content as HTMLElement;
    const $media: HTMLElement = this.$refs.media as HTMLElement;
    const $separator: HTMLElement = this.$refs.separator as HTMLElement;
    const $link: HTMLElement = (this.$refs.link as UnderlineButton)?.$el as HTMLElement;

    this.timeline
      .to(
        $side,
        {
          opacity: 0,
          duration: 0.5
        },
        'start'
      )
      .to(
        $title,
        {
          duration: 0.5,
          opacity: 0,
        },
        'start'
      )
      .to(
        $media,
        {
          duration: 0.5,
          opacity: 0,
        },
        'start'
      )
      .to(
        $content,
        {
          duration: 0.5,
          opacity: 0,
        },
        'start'
      )
      .to(
        $link,
        {
          duration: 0.5,
          opacity: 0,
        },
        'start'
      )
      .to(
        $separator,
        {
          duration: 0.5,
          opacity: 0,
        },
        'start'
      )

    const show = async () => {
      await Delay(300)
      gsap.timeline()
      .to(
        $content,
        {
          duration: 0.5,
          opacity: 1,
        },
        'start'
      )
      .to(
        $title,
        {
          duration: 0,
          opacity: 1,
        },
        'start'
      )
      .to(
        $content,
        {
          duration: 0.5,
          opacity: 1,
        },
        'start'
      )
      .to(
        $media,
        {
          duration: 0.5,
          opacity: 1,
        },
        'start'
      )
      .to(
        $link,
        {
          duration: 0.5,
          opacity: 1,
        },
        'start'
      )
      .to(
        $separator,
        {
          duration: 0.5,
          opacity: 1,
        },
        'start'
      )
    }
  }

  // Methods
  bindEvents() {
    document.addEventListener("pointerdown", this.onScreenClick);
  }

  unbindEvents() {
    document.removeEventListener("pointerdown", this.onScreenClick);
  }

  init() {
    const video = this.$refs.video as VideoMedia;
    if (video) video.init();
  }

  // Events
  closeContent() {
    globe_state.setCurrentCtn(-1);
    this.transitionOut(() => {
      const video = this.$refs.video as VideoMedia;
      if (video) video.kill();
    });
    // await Delay(1000);
    globe_state.setCurrentHotSpot(-1);

    // const video = this.$refs.video as VideoMedia
    // if (video) video.kill()
  }

  onScreenClick(e) {
    const $parents = e.path as Array<HTMLElement>;
    const $el = this.$refs.article as HTMLElement;
    if (e.target !== $el && $parents && !$parents.includes($el)) {
      this.closeContent();
    }
  }

  onResize() {
  }

  handlePrev() {
    const current = globe_state.currentHotSpot
    const prev = current > 0 ? current - 1 : content_state.globContent.length - 1
    this.changeData(() => {
      globe_state.setCurrentHotSpot(prev)
    })
  }

  handleNext() {
    const current = globe_state.currentHotSpot
    const next = current < content_state.globContent.length - 1 ? current + 1 : 0
    this.changeData(() => {
      globe_state.setCurrentHotSpot(next)
    })
  }
}
