






import Vue from "vue";
import Component from "vue-class-component";
import GLApp from "@/webgl/main";
import MainModule from "@/webgl/MainModule";
@Component
export default class GLView extends Vue {
  private viewer: GLApp;
  mounted() {
    const canvas: HTMLCanvasElement = this.$refs.canvas as HTMLCanvasElement;
    this.viewer = new GLApp(
      canvas as HTMLCanvasElement,
      new MainModule(this.onPreRender)
    );
  }

  onPreRender() {
    this.$emit("onPreRender");
  }

  play() {
    this.viewer.glview.play();
  }

  pause() {
    this.viewer.glview.pause();
  }

  async load() {
    await this.viewer.load();
  }
}
