










import Vue from "vue";
import Component from "vue-class-component";
import { gsap, Quart } from "gsap";
import globe_state from "@/store/modules/globe_state";

const SKIPLOADER = false;

@Component({
  components: {},
})
export default class Loader extends Vue {
  // Datas
  isLoader: Boolean = false;
  progress: number = 0;
  timeline: any;
  isFinishing: boolean = false;
  // Props
  // @Prop({type: String, required: true}) url: string

  // Lifecycle
  mounted() {
    this.isFinishing = false;
  }

  // Transitions
  transitionIn() {
    const $el: HTMLElement = this.$el as HTMLElement;
    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline().set($el, { opacity: 1 });
  }

  transitionOut(cb = () => {}) {
    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onComplete: () => {
        globe_state.setIsLoading(false);
        cb();
      },
    });

    const $el: HTMLElement = this.$el as HTMLElement;
    this.timeline.add(gsap.to($el, { opacity: 0, duration: 0.5 }));
  }

  // Methods
  finish(cb = () => {}) {
    this.isFinishing = true;
    this.transitionOut(cb);
  }

  init() {
    this.isLoader = true;

    Vue.nextTick().then(this.transitionIn);
    // const dur = 3;
    this.progress = 0;
    // gsap.to(this, {
    //   duration: dur / 2,
    //   progress: 50,
    //   ease: Quart.easeInOut,
    //   onUpdate: () => {
    //     this.setProgress(this.progress);
    //   },
    // });

    // gsap.to(this, {
    //   duration: dur / 2,
    //   delay: dur / 2,
    //   progress: 100,
    //   ease: Quart.easeInOut,
    //   onUpdate: () => {
    //     this.setProgress(this.progress);
    //   },
    // });
  }

  preRender() {
    this.progress += (globe_state.preload - this.progress) * 0.1;
    this.setProgress(this.progress);
    if (Math.ceil(this.progress) >= 100 && !this.isFinishing) {
      this.finish();
    }
  }

  setProgress(val): void {
    const $elements = this.$el.querySelectorAll(".loader__progress-bar > div");
    const $progress: HTMLElement = this.$el.querySelector(
      ".loader__progress"
    ) as HTMLElement;
    ($elements[0] as HTMLElement).style.transform = `scaleX(${Math.min(
      val,
      50
    ) * 2}%)` as string;
    ($elements[1] as HTMLElement).style.transform = `scaleX(${(Math.max(
      val,
      50
    ) -
      50) *
      2}%)` as string;
    $progress.innerHTML = `${Math.round(this.progress)}`;
  }
  // Events
}
