


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import content_state, { contentTypes } from "@/store/modules/content_state";
import DashedCircle from "!vue-svg-loader!@/assets/icons/dashed_circle.svg";
import Ball from "!vue-svg-loader!@/assets/icons/ball.svg";
import { gsap, Quart } from "gsap";
import { SplitText } from "@/vendors/SplitText";
import tag from "@/core/tag";
import globe_state from "@/store/modules/globe_state";

@Component({
  components: { Ball, DashedCircle },
})
export default class AllExperiencesCta extends Vue {
  // Datas
  timeline: any;
  hoverTimelineBall: any;
  hoverTimelineUnderline: any;
  hided: boolean = true;

  // Props
  // @Prop({type: String, required: true}) url: string

  // Lifecycle
  mounted() {
    const $label: HTMLElement = this.$refs.label as HTMLElement;
    const splitter = new SplitText({
      words: 0,
      chars: 1,
      spacing: "0.33em",
    });
    splitter.split($label);

    this.hoverTimelineUnderline = gsap.timeline({});
    this.hoverTimelineBall = gsap.timeline({});

    const $underline: HTMLElement = $label.querySelector("span") as HTMLElement;
    const $dashedCircle: SVGSVGElement = this.$refs
      .dashedCircle as SVGSVGElement;
    const $ball: SVGSVGElement = this.$refs.ball as SVGSVGElement;

    this.hoverTimelineUnderline
      .to(
        $underline,
        {
          duration: 0.7,
          transformOrigin: "right",
          scaleX: 0,
          ease: Quart.easeInOut,
        },
        0
      )
      .to(
        $underline,
        {
          duration: 0.7,
          transformOrigin: "left",
          scaleX: 1,
          ease: Quart.easeInOut,
        },
        "+=0"
      );

    this.hoverTimelineBall
      .fromTo(
        $dashedCircle,
        {
          scale: 1,
        },
        {
          duration: 0.9,
          scale: 1.2,
          ease: Quart.easeInOut,
        },
        0
      )
      .fromTo(
        $dashedCircle,
        {
          rotate: "10deg",
        },
        {
          duration: 1,
          rotate: "0",
          ease: Quart.easeInOut,
        },
        0
      )
      .fromTo(
        $ball,
        {
          scale: 1,
        },
        {
          duration: 0.9,
          scale: 1.1,
          ease: Quart.easeInOut,
        },
        0
      )
      .fromTo(
        $ball,
        {
          rotate: "10deg",
        },
        {
          duration: 1,
          rotate: "0",
          ease: Quart.easeInOut,
        },
        0
      );

    this.hoverTimelineUnderline.pause();
    this.hoverTimelineBall.pause();
    // const $chars = splitter.split($label).chars
    // console.log($chars)

    gsap.set(this.$refs.ball, {
      scale: 0
    })
  }

  // Transitions
  transitionIn(opts = { delay: 0, cb: () => {} }) {
    const $label: HTMLElement = this.$refs.label as HTMLElement;
    const $chars: NodeListOf<HTMLElement> = $label.querySelectorAll(
      "div"
    ) as NodeListOf<HTMLElement>;
    const $underline: HTMLElement = $label.querySelector("span") as HTMLElement;

    const $dashedCircle: SVGSVGElement = this.$refs
      .dashedCircle as SVGSVGElement;
    const $ball: SVGSVGElement = this.$refs.ball as SVGSVGElement;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onStart: () => {},
      onComplete: () => {
        this.hided = false;
        opts.cb();
      },
      delay: opts.delay,
    });

    this.timeline
      .to(
        [].slice.call($chars, 0).reverse(),
        {
          duration: 0.5,
          opacity: 1,
          y: "0rem",
          stagger: 0.015,
          ease: "power2.out",
        },
        0
      )
      .to(
        $underline,
        {
          transformOrigin: "right",
          duration: 0.5,
          scaleX: 1,
          ease: "power2.out",
        },
        0.3
      )
      .to(
        $dashedCircle,
        {
          duration: 0.5,
          scale: 1,
          opacity: 1,
          rotate: "10deg",
          ease: "power2.out",
        },
        0
      )
      .fromTo(
        $ball,
        {
          scale: 0,
        },
        {
          duration: 0.5,
          scale: 1,
          rotate: "10deg",
          ease: "power2.out",
        },
        0
      );

    return this.timeline;
  }

  transitionOut(opts = { delay: 0, cb: () => {} }) {
    const $label: HTMLElement = this.$refs.label as HTMLElement;
    const $chars: NodeListOf<HTMLElement> = $label.querySelectorAll(
      "div"
    ) as NodeListOf<HTMLElement>;
    const $underline: HTMLElement = $label.querySelector("span") as HTMLElement;

    const $dashedCircle: SVGSVGElement = this.$refs
      .dashedCircle as SVGSVGElement;
    const $ball: SVGSVGElement = this.$refs.ball as SVGSVGElement;

    if (this.hoverTimelineUnderline) this.hoverTimelineUnderline.kill();
    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({
      onStart: () => {},
      onComplete: () => {
        opts.cb();
        //  console.log('completed')
      },
      delay: opts.delay,
    });

    this.timeline
      .to(
        $chars,
        {
          duration: 0.45,
          opacity: 0,
          y: "0.7rem",
          stagger: 0.015,
          ease: Quart.easeIn,
        },
        "<"
      )
      .to(
        $dashedCircle,
        {
          duration: 1,
          scale: 0.55,
          opacity: 0,
          ease: Quart.easeInOut,
        },
        "<"
      )
      .to(
        $underline,
        {
          transformOrigin: "right",
          duration: 0.45,
          scaleX: 0,
          ease: Quart.easeInOut,
        },
        "<"
      )
      .to(
        $ball,
        {
          duration: 1,
          scale: 0,
          ease: Quart.easeInOut,
        },
        "<"
      );

    return this.timeline;
  }

  // Methods

  // Events
  click(): void {
    this.hided = true;
    content_state.setIsAllExperiences(true);
    globe_state.setIsAllExperiences(true);
    this.$emit("AllExperiencesCtaClicked");
    tag("mapExperienceDisplay");
  }

  onResize() {
    const $ball: SVGSVGElement = this.$refs.ball as SVGSVGElement;
    const $dashedCircle: SVGSVGElement = this.$refs.dashedCircle as SVGSVGElement;
    gsap.set([$ball, $dashedCircle], {
      x: "-50%",
      y: "-50%",
    });
  }

  onMouseHover() {
    if (this.hided || content_state.isMobile) return;

    if (this.hoverTimelineBall) this.hoverTimelineBall.kill();
    if (this.hoverTimelineUnderline) this.hoverTimelineUnderline.kill();
    this.hoverTimelineBall.restart();
    this.hoverTimelineUnderline.restart();
  }

  onMouseLeave() {
    if (this.hided || content_state.isMobile) return;

    this.hoverTimelineBall.reverse();
    this.hoverTimelineUnderline.restart();
  }
}
