











import Paths from "@/core/Paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Lottie from "lottie-web";
import { assetURL } from "@/common/webgl-vue";

interface AnimationItem {
  play(): void;
  stop(): void;
  pause(): void;
  setSpeed(speed: number): void;
  setDirection(direction: number): void;
  setSubframe(flag: boolean): void;
  goToAndPlay(value: number, isFrame: boolean): void;
  goToAndStop(value: number, isFrame: boolean): void;
  playSegments(segments: number[] | number[][], forceFlag: boolean): void;
  getDuration(inFrames: boolean): number;
  destroy(): void;
  currentFrame: number;
  frameRate: number;
}

@Component
export default class UIIcon extends Vue {
  // Datas
  animation: AnimationItem;

  // Props
  @Prop({ type: String, default: "svg" }) renderer: string;
  @Prop({ type: String, required: true }) slug: string;
  @Prop({ type: Boolean, required: false, default: true })
  isFrameBased: boolean;
  @Prop({ type: Boolean, required: false, default: false }) loop: boolean;
  @Prop({ type: Array, required: false, default: (_: any) => ([] = []) })
  steps: number[];

  // Lifecycle
  mounted() {
    this.init();
    this.animation.goToAndStop(0, this.isFrameBased);
    // setTimeout( (_: any) => {
    //   this.goToStep(1)
    // }, 3000)
    // setTimeout( (_: any) => {
    //   this.goToStep(0)
    // }, 6000)

    // setTimeout( (_: any) => {
    //   this.goToStep(2)
    // }, 7000)
  }

  destroy() {
    this.kill();
  }

  // Methods
  init(): void {
    this.animation = Lottie.loadAnimation({
      container: this.$el.querySelector(".lottie__container"),
      renderer: this.renderer as any,
      loop: this.loop,
      autoplay: false,
      path: assetURL(`assets/bodymovins/${this.slug}.json`),
    });
  }

  kill(): void {
    if (!this.animation) return;
    this.animation.destroy();
  }

  play(): void {
    if (!this.animation) return;
    this.setDirection(1);
    this.animation.play();
  }

  stop(): void {
    if (!this.animation) return;
    this.animation.stop();
  }

  pause(): void {
    if (!this.animation) return;
    this.animation.pause();
  }

  setSpeed(speed: number): void {
    if (!this.animation) return;
    this.animation.setSpeed(speed);
  }

  setDirection(direction: number): void {
    if (!this.animation) return;
    this.animation.setDirection(direction);
  }

  goToStep(step: number) {
    const steps = this.steps;
    const l = steps.length;
    if (!l || l + 1 < step) return;

    const to = this.steps[step];
    let current = this.steps[Math.max(0, step - 1)];

    // console.log("goToStep", current, to);
    this.animation.playSegments([current, to], true);
  }

  // Events
  onUpdate(): void {}
}
