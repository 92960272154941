export const hotspotPositions:Array<Array<number>> = []

export const assetsPath = process.env.NODE_ENV === "development" ? "" : "https://mapnba.hennessy.com/"
// export const assetsPath = process.env.NODE_ENV === "development" ? "" : "https://s3.eu-west-1.amazonaws.com/livraison.makemepulse.com/hennessy-x-nba-around-the-world/"

export const assetURL = (relativePath:string) => {
    return assetsPath + relativePath
}

export const US_HOTSPOT_NAME:string = "USA"

export const SCALE_HP = 0.75