


















import Vue from "vue";
import Component from "vue-class-component";
import Lottie from "@/components/Lottie/Lottie.vue";
import Delay from "@/core/Delay";
import content_state, { contentTypes } from "@/store/modules/content_state";
import { gsap, Quart } from "gsap";

// import Logo from "!vue-svg-loader!@/assets/icons/logo.svg";
@Component({
  components: {
    Lottie,
  },
})
export default class Thread extends Vue {
  // Datas
  timeline: any;
  changeTimeline: any;
  current: string = "";

  mainTitleInit = false;
  mainTitleOpen = false;

  // Getters

  get cocktailMode() {
    return content_state.contentMode === contentTypes.COCKTAIL;
  }

  get experiencesMode() {
    return content_state.isAllExperiences;
  }

  // Transitions
  transitionIn($el, opts = { delay: 0, cb: () => {} }) {
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
    });

    // if ($el.$el) $el = $el.$el;

    this.timeline.to(
      $el,
      {
        opacity: 1,
        duration: 1,
      },
      0
    );

    // (this.$refs.logo as Lottie).goToStep(1);

    return this.timeline;
  }

  transitionOut($el, opts = { delay: 0, cb: () => {} }) {
    this.timeline = gsap.timeline({
      onComplete: opts.cb,
      delay: opts.delay,
    });

    // console.log($el)

    this.timeline.to(
      $el,
      {
        opacity: 0,
        duration: 1,
      },
      0
    );
    // (this.$refs.logo as Lottie).goToStep(2);

    return this.timeline;
  }

  // Methods
  changeTitle(current, delay = 0) {
    // console.log(this.changeTitle)
    if (this.changeTimeline) this.changeTimeline.kill();
    this.changeTimeline = gsap.timeline({});
    if (this.mainTitleInit && this.mainTitleOpen) {
      (this.$refs.main as Lottie).goToStep(3);
      this.mainTitleOpen = false;
    }
    if (this.current.length > 0) {
      for (const [key, value] of Object.entries(this.$refs)) {
        if (key !== current)
          this.changeTimeline.add(this.transitionOut(value), 0);
      }
    }
    if (current !== "") {
      if (current === "main") {
        this.playMainTitle(delay);
        this.mainTitleInit = true;
        this.mainTitleOpen = true;
      } else
        this.changeTimeline.add(
          this.transitionIn(this.$refs[current] as HTMLElement)
        );
    }
    this.current = current;

    return this.changeTimeline;
  }

  async playMainTitle(delay) {
    await Delay(delay);
    (this.$refs.main as Lottie).animation.goToAndStop(140, true);
    await Delay(200);
    (this.$refs.main as Lottie).goToStep(1);
  }

  // Events
}
