var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\n#if __VERSION__ == 300\n  #define IN in\n  #define OUT out\n#else\n  #define IN attribute\n  #define OUT varying\n#endif\n\n\nIN vec2 aTexCoord0;\n\nOUT vec2 vTexCoordVP;\nOUT vec2 vTexCoordFull;\n\nuniform vec2 uViewportScale;\n\n'+
(obj.precode)+
'\n\nvoid main(void)\n{\n  vTexCoordVP   = aTexCoord0 * uViewportScale;\n  vTexCoordFull = aTexCoord0;\n\n  '+
(obj.code)+
'\n\n  gl_Position.xy = 2.0 * aTexCoord0-vec2(1.0,1.0);\n  gl_Position.zw = vec2(0.0,1.0);\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;