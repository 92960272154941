import { vec3, quat } from "gl-matrix";
import CinemachinePathBase from "@/webgl/lib/cinemachine/CinemachinePathBase";
import { Bezier3, BezierTangent3, Epsilon } from "@/webgl/lib/cinemachine/SplineHelpers";
import { DEG2RAD, mix } from "@/webgl/math";

const V3A = vec3.create();
const V3B = vec3.create();
const UP = vec3.fromValues(0.0, 1.0, 0.0);
const FWD = vec3.fromValues(0.0, 0.0, 1.0);

const QA = quat.create();
const QB = quat.create();

/// <summary>A waypoint along the path</summary>
export class Waypoint {
  /// <summary>Position in path-local space</summary>
  position: vec3;
  /// <summary>Offset from the position, which defines the tangent of the curve at the waypoint.  
  /// The length of the tangent encodes the strength of the bezier handle.  
  /// The same handle is used symmetrically on both sides of the waypoint, to ensure smoothness.</summary>
  tangent: vec3;
  /// <summary>Defines the roll of the path at this waypoint.  
  /// The other orientation axes are inferred from the tangent and world up.</summary>
  roll: number;

  constructor(position: vec3, tangent: vec3, roll: number = 0.0) {
    this.position = position;
    this.tangent = tangent;
    this.roll = roll;
  }

}

export default class CinemachinePath extends CinemachinePathBase {

  /// <summary>If checked, then the path ends are joined to form a continuous loop</summary>
  public m_Looped: boolean;

  /// <summary>The waypoints that define the path.
  /// They will be interpolated using a bezier curve</summary>
  public m_Waypoints: Waypoint[] = [];

  /// <summary>The minimum value for the path position</summary>
  public get MinPos(): number { return 0 }

  public get MaxPos(): number {
    let count = this.m_Waypoints.length - 1;
    if (count < 1)
      return 0;
    return this.m_Looped ? count + 1 : count;
  }

  /// <summary>True if the path ends are joined to form a continuous loop</summary>
  public get Looped(): boolean { return this.m_Looped }

  static CreateFromArrayBuffer(data: ArrayBuffer, res: number = 20): CinemachinePath {

    const sdata = new Float32Array(data);
    const waypoints = [];
    const stride = 8;
    let idx = 0;
    for (let i = 0; i <= sdata.length / stride - 1; i++) {
      // waypoints.push(
      //   new Waypoint(
      //     vec3.fromValues(sdata[idx + 0], sdata[idx + 1], sdata[idx + 2]),
      //     vec3.fromValues(sdata[idx + 5], sdata[idx + 6], sdata[idx + 7]),
      //     sdata[idx + 3]
      //   )
      // )
      waypoints.push(
        new Waypoint(
          vec3.fromValues(sdata[idx + 0], sdata[idx + 1], sdata[idx + 2]),
          vec3.fromValues(0, 0, 0),
          0
        )
      )
      idx += stride;
    }

    return new CinemachinePath(waypoints, res);
    
  }

  constructor(waypoints: Waypoint[], res: number = 20) {
    super();
    this.m_Waypoints = waypoints;
    this.m_Resolution = res;
  }

  private Reset(): void {
    this.m_Looped = false;
    this.m_Waypoints = [
      new Waypoint(vec3.fromValues(0, 0, -5), vec3.fromValues(1, 0, 0)),
      new Waypoint(vec3.fromValues(0, 0, -5), vec3.fromValues(1, 0, 0))
    ]

    this.InvalidateDistanceCache();

  }

  public get DistanceCacheSampleStepsPerSegment(): number {
    return this.m_Resolution;
  }

  /// <summary>Returns normalized position</summary>
  private GetBoundingIndices(pos: number, indexA: number = 0, indexB: number = 0) {
    pos = this.StandardizePos(pos);
    let rounded = Math.round(pos);
    if (Math.abs(pos - rounded) < Epsilon)
      indexA = indexB = (rounded == this.m_Waypoints.length) ? 0 : rounded;
    else {
      indexA = Math.floor(pos);
      if (indexA >= this.m_Waypoints.length) {
        pos -= this.MaxPos;
        indexA = 0;
      }
      indexB = Math.ceil(pos);
      if (indexB >= this.m_Waypoints.length)
        indexB = 0;
    }
    return {
      pos,
      indexA,
      indexB
    };
  }

  /// <summary>Get a worldspace position of a point along the path</summary>
  /// <param name="pos">Postion along the path.  Need not be normalized.</param>
  /// <returns>World-space position of the point along at path at pos</returns>
  public EvaluatePosition(pos: number): vec3 {
    let result: vec3 = vec3.create();
    let indices = this.GetBoundingIndices(pos);
    pos = indices.pos;
    let indexA = indices.indexA;
    let indexB = indices.indexB;
    if (indexA == indexB)
      result = this.m_Waypoints[indexA].position;
    else {
      // interpolate
      let wpA: Waypoint = this.m_Waypoints[indexA];
      let wpB: Waypoint = this.m_Waypoints[indexB];

      vec3.add(V3A, wpA.position, wpA.tangent);
      vec3.scaleAndAdd(V3B, wpB.position, wpB.tangent, -1.0);

      result = Bezier3(
        pos - indexA,
        this.m_Waypoints[indexA].position,
        V3A,
        V3B,
        wpB.position
      );
    }

    // WORLD SPACE
    return result;

  }


  /// <summary>Get the tangent of the curve at a point along the path.</summary>
  /// <param name="pos">Postion along the path.  Need not be normalized.</param>
  /// <returns>World-space direction of the path tangent.
  /// Length of the vector represents the tangent strength</returns>
  public EvaluateTangent(pos: number): vec3 {
    let result: vec3 = vec3.create();
    let indices = this.GetBoundingIndices(pos);
    pos = indices.pos;
    let indexA = indices.indexA;
    let indexB = indices.indexB;
    if (indexA == indexB)
      result = this.m_Waypoints[indexA].tangent;
    else {
      let wpA: Waypoint = this.m_Waypoints[indexA];
      let wpB: Waypoint = this.m_Waypoints[indexB];

      vec3.add(V3A, wpA.position, wpA.tangent);
      vec3.scaleAndAdd(V3B, wpB.position, wpB.tangent, -1.0);

      result = BezierTangent3(
        pos - indexA,
        this.m_Waypoints[indexA].position,
        V3A,
        V3B,
        wpB.position
      );
    }

    // WORLD SPACE
    return result;

  }



  /// <summary>Get the orientation the curve at a point along the path.</summary>
  /// <param name="pos">Postion along the path.  Need not be normalized.</param>
  /// <returns>World-space orientation of the path, as defined by tangent, up, and roll.</returns>
  public EvaluateOrientation(pos: number): quat {
    let result = quat.create();

    if (this.m_Waypoints.length > 0) {

      let roll = 0;

      let indices = this.GetBoundingIndices(pos);
      pos = indices.pos;
      let indexA = indices.indexA;
      let indexB = indices.indexB;


      if (indexA == indexB)
        roll = this.m_Waypoints[indexA].roll;
      else {
        let rollA = this.m_Waypoints[indexA].roll;
        let rollB = this.m_Waypoints[indexB].roll;
        if (indexB == 0) {
          // Special handling at the wraparound - cancel the spins
          rollA = rollA % 360;
          rollB = rollB % 360;
        }
        roll = mix(rollA, rollB, pos - indexA);
      }

      let dir = this.EvaluateTangent(pos);
      vec3.normalize(dir, dir);
      let fwd = vec3.fromValues(FWD[0], FWD[1], -FWD[2]);

      let m = vec3.length(dir)

      if (!(m < (Epsilon * Epsilon))) {

        let q = quat.create();

        if (UP != dir) {
          quat.rotationTo(q, fwd, dir)
        }
        else {
          quat.rotationTo(q, FWD, dir);
        }

        quat.setAxisAngle(QA, FWD, roll * DEG2RAD)
        quat.multiply(result, q, QA);

      }
    }
    return result;
  }

}