import Chunk from "@/webgl/lib/nanogl-pbr/src/Chunk";
import ChunksSlots from "@/webgl/lib/nanogl-pbr/src/ChunksSlots";
import Program from "nanogl/program";

export class RingAlpha extends Chunk {
    progress: number;
    start: number;
    constructor(){
      super(true, true)
      this.progress = 0
      this.start = 0.01
    }

    setup( prg:Program){
      prg.upp( this.progress )
      prg.us( this.start )
    }

    protected _genCode(slots: ChunksSlots): void {
        slots.add( 'pv', 'OUT vec2 vTexCoord;')
        slots.add( 'v', 'vTexCoord = aTexCoord0;')

        slots.add( 'pf', 'uniform float upp;')
        slots.add( 'pf', 'uniform float us;')
        slots.add( 'pf', 'IN vec2 vTexCoord;')
        slots.add( 'postf', 'FragColor.a = (1. - smoothstep(max(upp - 0.01, 0.), upp, vTexCoord.x)) * (1. - smoothstep(us, max(us - 0.1, 0.),  vTexCoord.x));')
        slots.add( 'postf', 'if (FragColor.a == 0.) { discard; }')
        // slots.add( 'postf', 'FragColor.rgb = vec3(vTexCoord.x, 0., 0.);')
    }

  }